<template>
   <div class="alert" :class="alertType">
      <span class="closebtn" @click.prevent="closeAlert">&times;</span>
      <h5 class="title" v-if="alertTitle">{{alertTitle}}</h5>
      <img v-if="icon" class="refresh-icon" src="../../assets/images/refresh-icon.svg" height="12"/>
      <div class="content" :class="{has_icon: icon}">
         <h5 v-if="alertMessage._group === 'text'">{{ alertMessage.title }}</h5>
         <p v-if="alertMessage._group === 'text'">{{ alertMessage.subtitle }}</p>
      </div>
   </div>
</template>

<script>
   export default {
      name: "Alerts",
      props: {
         icon: {
            type: Boolean,
            required: false,
            default: false
         },
         alertType: {
            type: String,
            required: true
         },
         alertTitle: {
            type: String
         },
         alertMessage: {
            type: String,
            required: true
         }
      },
      methods: {
         closeAlert() {
            this.$store.dispatch('alert/clear');
         }
      }
   }
</script>

<style scoped>

</style>
