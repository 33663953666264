import {Http} from "../_helpers/http";
import {config} from "../config";

const URL = config.apiUrl + '/api/events/';

export const events = {
   namespaced: true,
   state: {
      all: [],
      byID: {},
      bySlug: {},
   },
   actions: {
      getAll({commit}) {
         return Http.get(URL).then(resp => {
            commit('setAll', resp.data);
            return resp.data;
         })
      },
      getByID({commit}, id) {
         return Http.get(URL + id).then(resp => {
            commit('setByID', resp.data);
            return resp.data;
         })
      },
      getBySlug({commit}, slug) {
         return Http.get(URL + 'slug/' + slug).then(resp => {
            commit('setBySlug', resp.data);
            return resp.data;
         })
      }
   },
   mutations: {
      setAll(state, data) {
         state.all = data;
      },
      setByID(state, data) {
         state.byID = data;
      },
      setBySlug(state, data) {
         state.bySlug = data;
      }
   }
}
