<template>
    <div class="custom-checkboxes-tags-wrapper">
        <div class="custom-checkboxes-tags-inner d-flex flex-wrap justify-content-center">
            <label class="checkbox_option"
                   :class="{is_checked: value && value.includes(option.key)}"
                   v-for="(option, index) in options" :key="index+inputId"
            >
                <input type="checkbox"
                       :name="name+'[]'"
                       :value="option.key"
                       :required="required"
                       @change="checked($event, option)"
                       v-model='model'
                       :disabled="isDisabled(option)"
                > <span v-html="option.value"></span>
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InputTags",
        props: {
            value: [Object, String, Array, Boolean],
            name: {
                type: String,
                required: true
            },
            inputId: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: false
            },
            tabindex: {
                type: Number,
                required: false,
                default: 0
            },
            maxChoices: Number,
            options: Array,
            placeholder: String,
            required: Boolean,
            disabled: {
                type: Boolean,
                default: false
            },
        },
        model: {
            prop: 'value',
            event: 'update'
        },
        data() {
            return {
                model: []
            }
        },
        mounted() {
            if (this.value) {
                this.model = this.value;
            }
        },
        methods: {
            checked: function (event, option) {
                if (this.maxChoices === 1) {
                    if (this.model.indexOf(option.key) !== -1) {
                        this.model = [];
                        this.model.push(option.key);
                    }
                }
            },
            isDisabled: function (option) {
                if (!this.disabled && this.maxChoices > 1) {
                    return this.model.length >= this.maxChoices && this.model.indexOf(option.key) === -1;
                } else {
                    return this.disabled;
                }
            }
        },
        watch: {
            model: function (val) {
                this.$emit('update', val)
            },
            value: function (val) {
                if (val) {
                    this.model = val;
                } else {
                    this.model = [];
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
