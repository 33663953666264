import Vue from 'vue';
import Vuex from 'vuex';

import {alert} from './alert.module';
import {authentication} from './authentication.module';
import {menu} from './menu.module';
import {search} from './search.module';
import {timetable} from './timetable.module';
import {city} from './city.module';
import {news} from './news.module';
import {savedRoutes} from './savedRoutes.module';
// import {job} from './job.module';
import {faq} from './faq.module';
import {gallery} from './gallery.module';
import {blog} from './blog.module';
import {menuPosition} from './menuPosition.module';
import {departureMonitor} from './departureMonitor.module';
import {page} from './page.module';
import {region} from './region.module';
import {train} from './train.module';
import {station} from './station.module';
import {office} from './office.module';
import {events} from './events.module';
import {ticketSalesPoint} from './ticketSalesPoint.module';
import {line} from './line.module';
import {form} from './form.module';
import {loading} from './loading.module';
import {settings} from './settings.module';
import {employee} from './employee.module';
import {locales} from './locales.module';
import {internalJob} from './internalJob.module';
import {globalPopup} from "./globalPopup.module";
// import {jobPortal} from './jobPortal.module';
import {trafficNews} from './trafficNews.module';
import {constructionWork} from './constructionWork.module';
import {autoSuggestPlaces} from './autoSuggestPlaces.module';
import {timetableInformation} from './timetableInformation.module';
import {dramaticEvent} from "./dramaticEvent.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        alert,
        authentication,
        menu,
        search,
        timetable,
        city,
        faq,
        news,
        blog,
        menuPosition,
        page,
        region,
        train,
        station,
        form,
        ticketSalesPoint,
        events,
        office,
        gallery,
        loading,
        line,
        settings,
        employee,
        locales,
        internalJob,
        departureMonitor,
        globalPopup,
        // job,
        // jobPortal,
        trafficNews,
        constructionWork,
        autoSuggestPlaces,
        timetableInformation,
        savedRoutes,
        dramaticEvent
    }
});
