export const config = {
   alertTimeout: 3000,
   apiUrl: process.env.VUE_APP_API_ENDPOINT,
   jobPortalApiUrl: process.env.VUE_APP_JOB_PORTAL_API_ENDPOINT,
   externalServicesApiUrl: process.env.VUE_APP_ETERNAL_SERVICES_INTERFACE_API_ENDPOINT,
   mediaPath: process.env.VUE_APP_MEDIA_URL,
   cookieBotID: process.env.VUE_APP_COOKIE_BOT_ID,
   googleMapApiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
   mapStyle: [
      {
         "featureType": "all",
         "elementType": "labels",
         "stylers": [
            {
               "visibility": "on"
            }
         ]
      },
      {
         "featureType": "all",
         "elementType": "labels.text.fill",
         "stylers": [
            {
               "saturation": 36
            },
            {
               "color": "#000000"
            },
            {
               "lightness": 40
            }
         ]
      },
      {
         "featureType": "all",
         "elementType": "labels.text.stroke",
         "stylers": [
            {
               "visibility": "on"
            },
            {
               "color": "#000000"
            },
            {
               "lightness": 16
            }
         ]
      },
      {
         "featureType": "all",
         "elementType": "labels.icon",
         "stylers": [
            {
               "visibility": "off"
            }
         ]
      },
      {
         "featureType": "administrative",
         "elementType": "geometry.fill",
         "stylers": [
            {
               "color": "#000000"
            },
            {
               "lightness": 20
            }
         ]
      },
      {
         "featureType": "administrative",
         "elementType": "geometry.stroke",
         "stylers": [
            {
               "color": "#000000"
            },
            {
               "lightness": 17
            },
            {
               "weight": 1.2
            }
         ]
      },
      {
         "featureType": "administrative.country",
         "elementType": "labels.text.fill",
         "stylers": [
            {
               "color": "#838383"
            }
         ]
      },
      {
         "featureType": "administrative.locality",
         "elementType": "labels.text.fill",
         "stylers": [
            {
               "color": "#c4c4c4"
            }
         ]
      },
      {
         "featureType": "administrative.neighborhood",
         "elementType": "labels.text.fill",
         "stylers": [
            {
               "color": "#aaaaaa"
            }
         ]
      },
      {
         "featureType": "landscape",
         "elementType": "geometry",
         "stylers": [
            {
               "color": "#000000"
            },
            {
               "lightness": 20
            }
         ]
      },
      {
         "featureType": "poi",
         "elementType": "geometry",
         "stylers": [
            {
               "color": "#000000"
            },
            {
               "lightness": 21
            },
            {
               "visibility": "on"
            }
         ]
      },
      {
         "featureType": "poi.business",
         "elementType": "geometry",
         "stylers": [
            {
               "visibility": "on"
            }
         ]
      },
      {
         "featureType": "road.highway",
         "elementType": "geometry.fill",
         "stylers": [
            {
               "color": "#6e6e6e"
            },
            {
               "lightness": "0"
            }
         ]
      },
      {
         "featureType": "road.highway",
         "elementType": "geometry.stroke",
         "stylers": [
            {
               "visibility": "off"
            }
         ]
      },
      {
         "featureType": "road.highway",
         "elementType": "labels.text.fill",
         "stylers": [
            {
               "color": "#ffffff"
            }
         ]
      },
      {
         "featureType": "road.arterial",
         "elementType": "geometry",
         "stylers": [
            {
               "color": "#000000"
            },
            {
               "lightness": 18
            }
         ]
      },
      {
         "featureType": "road.arterial",
         "elementType": "geometry.fill",
         "stylers": [
            {
               "color": "#575757"
            }
         ]
      },
      {
         "featureType": "road.arterial",
         "elementType": "labels.text.fill",
         "stylers": [
            {
               "color": "#ffffff"
            }
         ]
      },
      {
         "featureType": "road.arterial",
         "elementType": "labels.text.stroke",
         "stylers": [
            {
               "color": "#2c2c2c"
            }
         ]
      },
      {
         "featureType": "road.local",
         "elementType": "geometry",
         "stylers": [
            {
               "color": "#000000"
            },
            {
               "lightness": 16
            }
         ]
      },
      {
         "featureType": "road.local",
         "elementType": "labels.text.fill",
         "stylers": [
            {
               "color": "#999999"
            }
         ]
      },
      {
         "featureType": "transit",
         "elementType": "geometry",
         "stylers": [
            {
               "color": "#000000"
            },
            {
               "lightness": 19
            }
         ]
      },
      {
         "featureType": "water",
         "elementType": "geometry",
         "stylers": [
            {
               "color": "#000000"
            },
            {
               "lightness": 17
            }
         ]
      }
   ],
   trafficNewsIds: {
      1: 66,
      2: 17,
      3: 67,
   },
   serviceAndContactIds: {
      1: 18,
      2: 53,
      3: 54,
   },
   timetablePageId: 13
};
