<template>
    <div class="container scroll_top_wrapper">
        <a href="#" @click.prevent="scrollTop" class="scroll_top_button">
            <i class="ab-icon-arrow-up"></i>
        </a>
    </div>
</template>

<script>
    export default {
        name: "ScrollTopButton",
        methods: {
            scrollTop() {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                })
            }
        }
    }
</script>

<style scoped>

</style>
