<template>
   <li v-if="localeMenuItem && localeMenuItem.title && localeMenuItem.link"
       @mouseenter="onMouseEneter"
       @mouseleave="onMouseLeave">

      <template v-if="isTimetableItem">
         <a href="#" @click.prevent="$store.commit('globalPopup/setTimetableOpen', true)">
            {{localeMenuItem.title}}
         </a>
      </template>

      <template v-else>
         <router-link v-if="menuItem.page_id" :to="localePrefix+localeMenuItem.link"
                      :target="(menuItem.is_new_tab)?'_blank':'_self'">
            {{localeMenuItem.title}}
         </router-link>
         <a v-else :href="localeMenuItem.link" :target="(menuItem.is_new_tab)?'_blank':'_self'" @click="handleClick">
            {{localeMenuItem.title}}
         </a>
      </template>
   </li>
</template>

<script>
   import $ from 'jquery';
   import {mapState} from 'vuex';
   import {config} from "../../config";

   export default {
      name: "MenuItems",
      props: {
         menuItem: Object
      },
      computed: {
         ...mapState([
            'locales'
         ]),
         localeMenuItem() {
            if (this.menuItem.locales_permalink[this.locales.currentLocale]) {
               return this.menuItem.locales_permalink[this.locales.currentLocale]
            }
            return null;
         },
         localePrefix() {
            return (this.locales.currentLocale != this.locales.defaultLocale) ? '/' + this.locales.currentLocale : '';
         },
         isTimetableItem() {
            return config.timetablePageId == this.menuItem.page_id
         }
      },
      methods: {
         onMouseEneter: function (e) {
            $(e.target).find('ul').stop(true, false).slideDown();
         },
         onMouseLeave: function (e) {
            $(e.target).find('ul').stop(true, false).slideUp();
         },
         handleClick: function (e) {
            if ($(e.target).attr('href') == '#') {
               e.preventDefault();
            }
         },
         openTimetableInformation: function () {

         }
      },
      mounted() {
         const thisElement = $(this.$el);
         if (thisElement.find('ul').length && 'ontouchstart' in document.documentElement) {
            thisElement.hover(function () {
            }, function () {
               $(this).find('a').removeClass('can_click');
            });
            thisElement.find('a').on('click', function (e) {
               if (!$(this).hasClass('can_click')) {
                  e.preventDefault();
                  $(this).addClass('can_click');
               }
            });
         }
      }
   }
</script>

<style scoped>

</style>
