import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.apiUrl + '/api/jobs/';

export const internalJob = {
   namespaced: true,
   state: {
      all: {
         jobs: [],
         categories: []
      },
      bySlug: {}
   },
   actions: {
      loadAll({commit}, data) {
         // console.log('load', data.page, data.categories)
         return Http.get(URL+ '?page=' + data.page + '&categories=' + data.categories).then(resp => {
            commit('setAll', resp.data);
            return resp.data;
         })
      },
      getBySlug({commit}, slug) {
         return Http.get(URL + 'slug/' + slug).then(resp => {
            commit('setBySlug', resp.data);
            return resp.data;
         })
      }
   },
   mutations: {
      setAll(state, data) {
         state.all.jobs = state.all.jobs.concat(data.jobs.data);
         state.all.categories = data.categories;
      },
      clearJobs(state){
        state.all.jobs = []
      },
      setBySlug(state, data) {
         state.bySlug = data;
      }
   }
}
