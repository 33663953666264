<template>
    <fieldset :class="{is_disabled: disabled}" v-if="label">
        <legend><label :for="inputId">{{label}}<template v-if="required"> *</template></label></legend>
        <textarea
            :name="name"
            :id="inputId"
            :placeholder="placeholder"
            :value="value"
            @input="$emit('update', $event.target.value)"
            @blur="$emit('blur', $event.target.value)"
            @focus="$emit('focus', $event.target.value)"
            :required="required"
            :disabled="disabled"
            @keyup.enter="$emit('keyupEnter')"
        ></textarea>
    </fieldset>
    <textarea v-else
        :name="name"
        :id="inputId"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('update', $event.target.value)"
        @blur="$emit('blur', $event.target.value)"
        @focus="$emit('focus', $event.target.value)"
        :required="required"
        :disabled="disabled"
        @keyup.enter="$emit('keyupEnter')"
    ></textarea>
</template>

<script>
    export default {
        name: "InputTextarea",
        props: {
            value: String,
            name: {
                type: String,
                required: true
            },
            inputId: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: false
            },
            placeholder: String,
            required: Boolean,
            disabled: Boolean,
        },
        model: {
            prop: 'value',
            event: 'update'
        }
    }
</script>

<style scoped>

</style>
