import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.apiUrl + 'api/form/';

export const form = {
   namespaced: true,
   state: {
      config: {
         faq: [],
         topics: []
      },
   },
   actions: {
      getConfig({commit}) {
         return Http.get(URL).then(resp => {
            commit('setConfig', resp.data);
            return resp.data;
         })
      },
      submitForm(context, formData) {
         return Http.post(URL + 'data', formData, {
            headers: {
               "Content-Type": "multipart/form-data"
            }
         }).then(resp => {
            return resp.data;
         })
      },
      submitFormBuilder({dispatch}, {formData, message}) {
         return Http.post(URL + 'builder', formData).then(resp => {
            dispatch('alert/success', {message: message.success_message}, {root: true});
            return resp.data;
         })
      }
   },
   mutations: {
      setConfig(state, data) {
         state.config = data;
      }
   }
}
