export const timetable = {
   namespaced: true,
   state: {
      date: null,
      time: null,
      hasData: false,
      timetableTo: "",
      timetableFrom: "",
      timetableToObject: null,
      timetableFromObject: null,
   },
   mutations: {
      setData(state, data) {
         state.hasData = true;
         state.date = data.date;
         state.time = data.time;

         state.timetableFrom = data.timetableFrom;
         state.timetableTo = data.timetableTo;
         state.timetableFromObject = data.timetableFromObject;
         state.timetableToObject = data.timetableToObject;
      },
      clearData(state) {
         state.hasData = true;
         state.date = null;
         state.time = null;

         state.timetableFrom = "";
         state.timetableTo = "";
         state.timetableFromObject = null;
         state.timetableToObject = null;
      }
   }
}
