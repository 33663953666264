<template>
   <div class="my-places">
      <popup :visible="newPlacePopupVisible" v-on:popupClose="closeNewPopup()">
         <template v-slot:header><p>{{ __t('my_places.new_place_title') }}</p></template>

         <div class="timetable_input_autocomplete_wrapper timetable_to_wrapper"></div>

         <div class="timetable_input_autocomplete_wrapper timetable_to_wrapper">
            <InputText :name="'Name'"
                       :placeholder="'Name'"
                       :label="__t('my_places.name')"
                       v-model="newPlace.name"
                       inputId="name"
                       :disabled="false"/>

            <div class="icons">
               <label>Typ</label>
               <ul class="icons-list">
                  <li><i @click="newPlace.type = 1" class="ab-icon-home cursor-pointer"
                         :class="{active: newPlace.type === 1}"></i>
                  </li>
                  <li><i @click="newPlace.type = 2" class="ab-icon-job cursor-pointer"
                         :class="{active: newPlace.type === 2}"></i>
                  </li>
                  <li><i @click="newPlace.type = 3" class="ab-icon-train cursor-pointer"
                         :class="{active: newPlace.type === 3}"></i>
                  </li>
                  <li><i @click="newPlace.type = 4" class="ab-icon-events cursor-pointer"
                         :class="{active: newPlace.type === 4}"></i></li>
               </ul>
            </div>

            <div class="timetable_input_autocomplete_wrapper timetable_to_wrapper">
               <InputText :name="'Ort'"
                          :type="'text'"
                          :placeholder="'Ort'"
                          :label="__t('my_places.select_station')"
                          v-model="newPlaceInput"
                          inputId="ort"
                          :disabled="false"
                          aria-autocomplete="chrome-off"
                          :newPlacePopupActive="true"
                          @focus="resetAutosuggestedPlaces()"/>
               <span v-if="timetableFromLoading" class="loading-icon">
               <svg viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd" transform="translate(1 1)" stroke-width="2">
                     <circle cx="22" cy="22" r="6" stroke-opacity="0">
                        <animate attributeName="r"
                                 begin="1.5s" dur="3s"
                                 values="6;22"
                                 calcMode="linear"
                                 repeatCount="indefinite"/>
                        <animate attributeName="stroke-opacity"
                                 begin="1.5s" dur="3s"
                                 values="1;0" calcMode="linear"
                                 repeatCount="indefinite"/>
                        <animate attributeName="stroke-width"
                                 begin="1.5s" dur="3s"
                                 values="2;0" calcMode="linear"
                                 repeatCount="indefinite"/>
                     </circle>
                     <circle cx="22" cy="22" r="6" stroke-opacity="0">
                        <animate attributeName="r"
                                 begin="3s" dur="3s"
                                 values="6;22"
                                 calcMode="linear"
                                 repeatCount="indefinite"/>
                        <animate attributeName="stroke-opacity"
                                 begin="3s" dur="3s"
                                 values="1;0" calcMode="linear"
                                 repeatCount="indefinite"/>
                        <animate attributeName="stroke-width"
                                 begin="3s" dur="3s"
                                 values="2;0" calcMode="linear"
                                 repeatCount="indefinite"/>
                     </circle>
                     <circle cx="22" cy="22" r="8">
                        <animate attributeName="r"
                                 begin="0s" dur="1.5s"
                                 values="6;1;2;3;4;5;6"
                                 calcMode="linear"
                                 repeatCount="indefinite"/>
                     </circle>
                  </g>
               </svg>
            </span>


               <div class="timetable_input_autocomplete" :class="{is_open: newPlacePopupActive}">
                  <div class="timetable_input_autocomplete_inner">
                     <a href="#" class="timetable_input_autocomplete_item"
                        v-for="(place, index) in autoSuggestPlaces.places" :key="index"
                        @click.prevent="selectNewPlace(place)">
                        <span class="icon"><i class="ab-icon-train"></i></span>
                        {{ place.name }}
                     </a>
                  </div>
               </div>
            </div>
         </div>

         <template v-slot:footer>
            <div class="d-flex flex-column align-items-center login-button">
               <button class="btn btn-grey" @click="addNewPlace">{{ __t('save') }}</button>
            </div>
         </template>
      </popup>

      <popup :visible="editPlacePopupVisible" v-if="editPlace" v-on:popupClose="closeEditPopup()">
         <template v-slot:header><p>{{ __t('my_places.edit_place_title') }}</p></template>

         <div class="timetable_input_autocomplete_wrapper timetable_to_wrapper"></div>

         <div class="timetable_input_autocomplete_wrapper timetable_to_wrapper">
            <InputText :name="'Name'"
                       :placeholder="'Name'"
                       :label="__t('my_places.name')"
                       v-model="editPlace.name"
                       inputId="name"
                       :disabled="false"/>


            <div class="icons">
               <label>Typ</label>
               <ul class="icons-list">
                  <li><i @click="editPlace.type = 1" class="ab-icon-home cursor-pointer"
                         :class="{active: editPlace.type === 1}"></i>
                  </li>
                  <li><i @click="editPlace.type = 2" class="ab-icon-job cursor-pointer"
                         :class="{active: editPlace.type === 2}"></i>
                  </li>
                  <li><i @click="editPlace.type = 3" class="ab-icon-train cursor-pointer"
                         :class="{active: editPlace.type === 3}"></i>
                  </li>
                  <li><i @click="editPlace.type = 4" class="ab-icon-events cursor-pointer"
                         :class="{active: editPlace.type === 4}"></i></li>
               </ul>
            </div>

            <div class="timetable_input_autocomplete_wrapper timetable_to_wrapper">
               <InputText :name="'Ort'"
                          :type="'text'"
                          :placeholder="'Ort'"
                          :label="__t('my_places.select_station')"
                          v-model="editPlaceInput"
                          inputId="ort"
                          :disabled="false"
                          @focus="resetAutosuggestedPlaces()"/>

               <span v-if="timetableFromLoading" class="loading-icon">
               <svg viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd" transform="translate(1 1)" stroke-width="2">
                     <circle cx="22" cy="22" r="6" stroke-opacity="0">
                        <animate attributeName="r"
                                 begin="1.5s" dur="3s"
                                 values="6;22"
                                 calcMode="linear"
                                 repeatCount="indefinite"/>
                        <animate attributeName="stroke-opacity"
                                 begin="1.5s" dur="3s"
                                 values="1;0" calcMode="linear"
                                 repeatCount="indefinite"/>
                        <animate attributeName="stroke-width"
                                 begin="1.5s" dur="3s"
                                 values="2;0" calcMode="linear"
                                 repeatCount="indefinite"/>
                     </circle>
                     <circle cx="22" cy="22" r="6" stroke-opacity="0">
                        <animate attributeName="r"
                                 begin="3s" dur="3s"
                                 values="6;22"
                                 calcMode="linear"
                                 repeatCount="indefinite"/>
                        <animate attributeName="stroke-opacity"
                                 begin="3s" dur="3s"
                                 values="1;0" calcMode="linear"
                                 repeatCount="indefinite"/>
                        <animate attributeName="stroke-width"
                                 begin="3s" dur="3s"
                                 values="2;0" calcMode="linear"
                                 repeatCount="indefinite"/>
                     </circle>
                     <circle cx="22" cy="22" r="8">
                        <animate attributeName="r"
                                 begin="0s" dur="1.5s"
                                 values="6;1;2;3;4;5;6"
                                 calcMode="linear"
                                 repeatCount="indefinite"/>
                     </circle>
                  </g>
               </svg>
            </span>
               <!-- <input type="text" placeholder="Ort" v-model="editPlaceInput" @focus="resetAutosuggestedPlaces()"> -->
               <div class="timetable_input_autocomplete" :class="{is_open: placesPopupPlaceActive}">
                  <div class="timetable_input_autocomplete_inner">
                     <a href="#" class="timetable_input_autocomplete_item"
                        v-for="(place, index) in autoSuggestPlaces.places" :key="index"
                        @click.prevent="selectEditPlace(place)">
                        <span class="icon"><i class="ab-icon-train"></i></span>
                        {{ place.name }}
                     </a>
                  </div>
               </div>
            </div>
         </div>

         <template v-slot:footer>
            <div class="d-flex flex-column align-items-center login-button">
               <button class="btn btn-grey" @click="updatePlace">{{ __t('update') }}</button>
               <button class="btn btn-red" @click="deletePlace">{{ __t('delete') }}</button>
            </div>
         </template>
      </popup>
   </div>
</template>

<script>
   import Popup from "./Popup";
   import {mapState} from 'vuex';
   import {debounce} from "debounce";
   import InputText from "../_form/InputText";

   export default {
      name: "MyPlaces",
      components: {InputText, Popup},
      props: {
         newPlacePopupVisible: {
            type: Boolean,
            default: false
         },
         editPlacePopupVisible: {
            type: Boolean,
            default: false
         },
         editPlace: {
            required: true,
            default() {
               return {
                  id: null,
                  name: null,
                  station: '',
                  type: 1
               }
            }
         },
      },
      data() {
         return {
            newPlace: {
               id: null,
               name: null,
               station: '',
               type: 1
            },

            minCharsSearchStart: 0,
            newPlaceInput: '',
            editPlaceInput: '',
            placesPopupPlaceActive: true,
            newPlacePopupActive: false,
            timetableFromLoading: false,
            inputChangedFromAutoSuggest: false,
            editPopupFirstInputChanged: false,
         }
      },
      computed: {
         ...mapState([
            'locales',
            'region',
            'autoSuggestPlaces',
         ])
      },
      methods: {
         addNewPlace() {
            this.$store.commit('loading/setFullPageLoaderVisible', true);
            let myPlaces = JSON.parse(localStorage.getItem('myPlaces'));

            if (myPlaces === null) {
               localStorage.setItem('myPlaces', JSON.stringify([this.newPlace]));
            } else {
               if (this.newPlace.id === null)
                  this.newPlace.id = Date.now();

               myPlaces.push(this.newPlace);
               localStorage.setItem('myPlaces', JSON.stringify(myPlaces));
            }

            let self = this;
            setTimeout(function () {
               self.$emit('updated')
               self.$store.commit('loading/setFullPageLoaderVisible', false);
               self.closeNewPopup()
               this.newPlaceInput = ''
            }, 1000);
         },
         deletePlace() {
            let self = this;
            this.$store.commit('loading/setFullPageLoaderVisible', true);
            let myPlaces = JSON.parse(localStorage.getItem('myPlaces'));
            if (myPlaces !== null) {
               let newPlaces = [];

               myPlaces.forEach(function (place) {
                  if (self.editPlace.id !== place.id) {
                     newPlaces.push(place);
                  }
               })

               localStorage.setItem('myPlaces', JSON.stringify(newPlaces));
            }

            setTimeout(function () {
               self.$emit('updated')
               self.$store.commit('loading/setFullPageLoaderVisible', false);
               self.closeEditPopup()
            }, 1000);
         },
         updatePlace() {
            let self = this;
            this.$store.commit('loading/setFullPageLoaderVisible', true);
            let myPlaces = JSON.parse(localStorage.getItem('myPlaces'));
            if (myPlaces !== null) {
               let newPlaces = [];

               myPlaces.forEach(function (place) {
                  if (self.editPlace.id === place.id) {
                     newPlaces.push(self.editPlace);
                  } else {
                     newPlaces.push(place);
                  }
               })

               localStorage.setItem('myPlaces', JSON.stringify(newPlaces));
            }

            setTimeout(function () {
               self.$emit('updated')
               self.$store.commit('loading/setFullPageLoaderVisible', false);
               self.closeEditPopup()
            }, 1000);
         },
         resetAutosuggestedPlaces() {
            this.$store.commit('autoSuggestPlaces/setPlaces', []);
         },
         selectNewPlace(place) {
            this.newPlace.id = place.id;
            this.newPlace.station = place.name;
            this.newPlaceInput = place.name;
            this.newPlacePopupActive = false;
            this.resetAutosuggestedPlaces();
            this.inputChangedFromAutoSuggest = true
         },
         selectEditPlace(place) {
            this.editPlace.id = place.id;
            this.editPlace.station = place.name;
            this.editPlaceInput = place.name;
            this.resetAutosuggestedPlaces();
            this.inputChangedFromAutoSuggest = true
         },
         closeNewPopup() {
            this.resetNewPlace();
            this.$emit('newPlacePopupClosed', true);
         },
         closeEditPopup() {
            this.resetNewPlace();
            this.$emit('editPlacePopupClosed', true);
         },
         resetNewPlace() {
            this.newPlace = {
               id: null, name: null, station: '', type: 1
            }
            this.newPlaceInput = this.editPlaceInput = ''
            this.timetableFromLoading = false;
            this.inputChangedFromAutoSuggest = false;
         }
      },
      watch: {
         editPlacePopupVisible: function (val) {
            if (val) {
               this.editPopupFirstInputChanged = false;
               this.editPlaceInput = this.editPlace.station;
               this.$store.commit('autoSuggestPlaces/setPlaces', []);
            }
         },
         newPlaceInput: debounce(function (val) {
            if (val && val.length > this.minCharsSearchStart && this.newPlace.name !== val && !this.inputChangedFromAutoSuggest) {
               this.timetableFromLoading = true;
               let self = this;
               this.$store.dispatch('autoSuggestPlaces/getAutoSuggestion', val).then(function () {
                  self.timetableFromLoading = false
               });
               this.inputChangedFromAutoSuggest = false;
            }
         }, 400),
         editPlaceInput: debounce(function (val) {
            if (!this.editPopupFirstInputChanged) {
               this.editPopupFirstInputChanged = true;
            } else if (val && val.length > this.minCharsSearchStart && !this.inputChangedFromAutoSuggest) {
               this.timetableFromLoading = true;
               let self = this;
               this.$store.dispatch('autoSuggestPlaces/getAutoSuggestion', val).then(function () {
                  self.timetableFromLoading = false
               });
               this.inputChangedFromAutoSuggest = false;
            }
         }, 400),
         newPlacePopupVisible: function () {
            this.$store.commit('autoSuggestPlaces/setPlaces', []);
         }
      }
   }
</script>

<style lang="scss">
   .timetable_my_locations_item {
      background: $abellio-red;
   }
</style>
