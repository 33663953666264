import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.apiUrl + '/api/locales/';

export const locales = {
    namespaced: true,
    state: {
        locales: {
            'de': "Deutsch",
            'en': "English",
            'nl': "Dutch"
        },
        defaultLocale: 'de',
        currentLocale: 'de',
        stringTranslations: []
    },
    actions: {
        getStringTranslations({commit}) {
            return Http.get(URL + 'static-content').then(resp => {
                commit('setStringTranslations', resp.data);
                return resp.data;
            })
        }
    },
    mutations: {
        setDefaultLocale(state, data) {
            state.defaultLocale = data;
        },
        setCurrentLocale(state, data) {
            state.currentLocale = data;
        },
        setStringTranslations(state, data) {
            state.stringTranslations = data;
        }
    }
}
