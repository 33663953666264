<template>
   <div class="timetable_element sticky-timetable" :class="{ is_open: globalPopup.isDepartureMonitorOpen }">
      <div class="timetable_wrapper">
         <div class="timetable_inner_wrapper">
            <div class="navigation_overlay"></div>
            <div class="timetable_inner_inner">
               <h5 class="timetable_title">{{ __t('departure_monitor.headline') }}</h5>
               <div class="timetable_controls_wrapper">

                  <div class="timetable_inputs_wrapper">
                     <div class="timetable_input_wrapper">
                        <div class="timetable_input_autocomplete_wrapper timetable_from_wrapper">
                           <label for="timetable_from_dep_monitor">{{ __t('departure_monitor.select_station') }}</label>
                           <input
                              id="timetable_from_dep_monitor"
                              type="text"
                              :placeholder="__t('departure_monitor.placeholder')"
                              v-model="station"
                              autocomplete="off"
                              @blur="inputBlur"
                              @focus="stationsActive=true;"/>
                           <div class="timetable_input_autocomplete" :class="{ is_open: stationsActive }">
                              <div class="timetable_input_autocomplete_inner">
                                 <template v-if="filteredStations">
                                    <a href="#" class="timetable_input_autocomplete_item"
                                       v-for="(station, index) in filteredStations"
                                       :key="'fromas-' + index"
                                       @click.prevent="selectStation(station.key)">
                                       <span class="icon"><i class="ab-icon-headquarters"></i></span>
                                       {{ station.name }}
                                    </a>
                                 </template>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <a href="#" class="close_button" @click.prevent="closePopup()"></a>
            </div>
         </div>
         <div class="timetable_submit_wrapper d-flex flex-column align-items-center" v-if="station != null">
            <button class="btn btn-grey btn-suche animate__fadeInUp" @click="submitForm()">
               {{ __t('search') }}
            </button>
         </div>
      </div>
   </div>
</template>

<script>
   import {mapState} from "vuex";
   import {config} from "../../config";

   export default {
      name: "DepartureMonitorPopup",
      props: {
         data: Object,
      },
      data() {
         return {
            station: null,
            stationsActive: false,
            stationsLoading: false,
            minCharsSearchStart: 2,
         };
      },
      computed: {
         ...mapState([
            "globalPopup",
            "departureMonitor",
         ]),
         filteredStations() {
            let self = this;
            if (this.departureMonitor.allStations) {
               if (self.station) {
                  let result = [];
                  this.departureMonitor.allStations.forEach(function (station) {
                     if (station.name.toLowerCase().includes(self.station.toLowerCase())) {
                        result.push(station);
                     }
                  });
                  return result;
               } else {
                  return this.departureMonitor.allStations;
               }
            }

            return [];
         }
      },
      methods: {
         inputBlur() {
            let self = this;
            setTimeout(function () {
               self.stationsActive = false;
            }, 100);
         },
         navigate(url) {
            window.location.href = url;
            this.closePopup();
         },
         selectStation(stationKey) {
            this.stationsActive = false;

            this.$router.push({name: 'departureMonitor', query: {station: stationKey}});

         },
         closePopup() {
            this.$store.commit('globalPopup/setDepartureMonitorOpen', !this.globalPopup.isDepartureMonitorOpen);
            this.$store.commit("departureMonitor/setAllStations", []);
            this.station = "";
            this.stationsLoading = false;
         },
      },
      created() {
         let self = this
         this.$store.dispatch("departureMonitor/getAllStations");

         this.$store.dispatch('page/getByID', config.timetablePageId).then((data) => {
            self.timetableInformationPath = data.slug
         })
      }
   };
</script>

<style scoped>
</style>
