import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.apiUrl + '/api/faq/';

export const faq = {
    namespaced: true,
    state: {
        all: []
    },
    actions: {
        getAll({commit}) {
            return Http.get(URL).then(resp => {
                commit('setAll', resp.data);
                return resp.data;
            })
        },
    },
    mutations: {
        setAll(state, data) {
            state.all = data;
        }
    }
}
