import {Http} from "../_helpers/http";
import {config} from "../config";
import {store} from "./index";

const URL = config.externalServicesApiUrl + '/construction-work';

export const constructionWork = {
    namespaced: true,
    state: {
        all: [],
        byID: {},
    },
    actions: {
        getAll({commit}) {
            return Http.get(URL, {params: {region: store.state.region.currentRegion.id}}).then(resp => {
                commit('setAll', resp.data.data);
                return resp.data.data;
            })
            .catch(err => {
                throw err
            })
        },
        getByID({commit}, id) {
            return Http.get(URL + '/' + id, {params: {region: store.state.region.currentRegion.id}}).then(resp => {
                commit('setByID',resp.data.data);
                return resp.data.data;
            })
            .catch(err => {
                throw err
            })
        }
    },
    mutations: {
        setAll(state, data) {
            state.all = data;
        },
        setByID(state, data) {
            state.byID = data;
        }
    }
}
