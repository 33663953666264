import {router} from '../_helpers';
import {store} from '../_store';
import {AuthHttp, Http} from "../_helpers/http";
import {config} from "../config";

const data = JSON.parse(localStorage.getItem('user'));
const initialState = data ? {loggedIn: true, user: data} : {loggedIn: false, user: null};

export const authentication = {
   namespaced: true,
   state: initialState,
   actions: {
      login({dispatch, commit}, {email, password}) {
         Http.post(config.apiUrl + 'api/login', {email: email, password: password}).then(data => {
            data = data.data;
            if (data.token) {
               localStorage.setItem('user', JSON.stringify(data));
               commit('loginSuccess', data);
               dispatch('alert/success', {title: 'Login', message: 'Logged in successfully'}, {root: true});
               if (router.currentRoute.params.nextUrl) {
                  router.push({
                     path: router.currentRoute.params.nextUrl
                  });
               } else {
                  store.commit('loginPopUp/setLoginOpen', false)
                  router.push({
                     name: 'personal-area'
                  });
               }
            }
         }).catch(error => {
            commit('loginFailure');
            dispatch('alert/error', {message: error.response.data.message}, {root: true});
         })
      },
      logout({commit}) {
         commit('logout');
      },
      refresh({dispatch, commit}) {

         const user = JSON.parse(localStorage.getItem('user'));

         if (user && user.token) {
            AuthHttp.post(config.apiUrl + '/api/refresh', {token: user.token}).then(data => {
               localStorage.setItem('user', JSON.stringify(data));
               commit('loginSuccess', data);
            }).catch(() => {
               dispatch('logout');
            })
         }
      },
      register({dispatch, commit}, {title, name, surname, email, password, password_confirmation}) {
         let data = {
            title,
            name,
            surname,
            email,
            password,
            password_confirmation
         };
         Http.post(config.apiUrl + '/api/users/create', data).then(data => {
            data = data.data;
            if (data.token) {
               localStorage.setItem('user', JSON.stringify(data));
               commit('loginSuccess', data);
               dispatch('alert/success', {message: 'User registered successfully'}, {root: true});
               router.push({
                  name: 'home'
               });
            }
         }).catch(error => {
            dispatch('alert/error', {message: error.response.data.message}, {root: true});
         })
      },
      restorePassword({dispatch}, {email}) {
         Http.post(config.apiUrl + '/api/users/restore-password', {email: email}).then(() => {
            dispatch('alert/success', {message: 'If your email is registered within the system then an activation link has been sent to your email!'}, {root: true});
         }).catch(error => {
            dispatch('alert/error', {message: error.response.data.message}, {root: true});
         })
      },
      resetPassword({dispatch}, {code, password, password_confirmation}) {
         let data = {
            code,
            password,
            password_confirmation
         };
         Http.post(config.apiUrl + '/api/users/reset-password', data).then(() => {
            dispatch('alert/success', {message: 'Password changed successfully!'}, {root: true});
         }).catch(error => {
            dispatch('alert/error', {message: error.response.data.message}, {root: true});
         })
      },
      changePassword({dispatch}, {old_password, new_password, password_confirmation}) {
         let data = {
            old_password,
            password: new_password,
            password_confirmation
         };
         AuthHttp.post(config.apiUrl + '/api/users/change-password', data).then(() => {
            dispatch('alert/success', {message: 'Password changed successfully!'}, {root: true});
         }).catch(error => {
            dispatch('alert/error', {message: error.response.data.message}, {root: true});
         })
      },
      deleteUser({dispatch, commit}) {
         AuthHttp.delete(config.apiUrl + '/api/users/delete').then(() => {
            // data = data.data;
            //commit('delete');
            commit('delete');
            dispatch('alert/success', {message: 'Account deleted successfully!'}, {root: true});
         }).catch(error => {
            //commit('loginFailure');
            dispatch('alert/error', {message: error.response.data.message}, {root: true});
         })
      }
   },
   mutations: {
      loginSuccess(state, user) {
         state.loggedIn = true;
         state.user = user;
      },
      loginFailure(state) {
         state.loggedIn = false;
         state.user = null;
      },
      logout(state) {
         if (state.user && state.user.token) {
            AuthHttp.post(config.apiUrl + '/api/invalidate', {token: state.user.token})
            // .then(data => {
            //    window.console.log('success', data);
            // })
         }

         state.loggedIn = false;
         state.user = null;
         localStorage.removeItem('user');
         router.push('/');
      },
      delete(state) {
         state.loggedIn = false;
         state.user = null;
         localStorage.removeItem('user');
         router.push('/login');
      }
   }
}
