import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.apiUrl + '/api/search/';

export const search = {
   namespaced: true,
   state: {
      autoSuggest: [],
      result: {
         pages: [],
         lines: []
      }
   },
   actions: {
      autoSuggest({commit}, query) {
         return Http.get(URL + 'auto-suggest', {params: {q: query}}).then(resp => {
            commit('setAutoSuggest', resp.data);
            return resp.data;
         })
      },
      searchAll({commit}, query) {
         return Http.get(URL, {params: {q: query}}).then(resp => {
            commit('setResult', resp.data);
            return resp.data;
         })
      },
   },
   mutations: {
      setAutoSuggest(state, data) {
         state.autoSuggest = data.result;
      },
      setResult(state, data) {
         state.result = data.result;
      }
   }
}
