<template>
   <div class="navigation_wrapper d-flex justify-content-center text-center" :class="{is_open: globalPopup.isMenuOpen}">
      <div class="navigation_overlay" @click="$store.commit('globalPopup/setMenuOpen', false)"></div>
      <div class="navigation_inner">
         <ul class="first_level">
            <MenuItems v-for="item in menu.mainMenu.menu_items" :menu-item="item" :key="item.id"/>
         </ul>

<!--         <LocaleChanger/>-->
         <div data-v-8e7a8006="" class="d-flex input-wrapper position-relative" v-if="isMobile">
            <i data-v-8e7a8006="" class="ab-icon-search search-icon">
            </i>
            <input data-v-8e7a8006="" type="text" placeholder="Suche" v-model="searchInput" autocomplete="off" class="search-input">
            <button type="submit" @click.prevent="search()">Suche</button>
            <div data-v-8e7a8006="" class="m-autocomplete custom-scrollbar is-active"></div>
         </div>
      </div>
<!--      <div class="user_profile_wrapper d-flex justify-content-center">-->
<!--         <template v-if="authentication.loggedIn && authentication.user.user">-->
<!--            <a href="#" @click.prevent="$store.commit('globalPopup/setLoginOpen', true)"-->
<!--               class="user_profile user-account">-->
<!--               <span class="icon"></span>-->
<!--               {{ authentication.user.user.name }} {{ authentication.user.user.surname }}-->
<!--            </a>-->
<!--            <a class="user_profile logout-btn" @click="$store.dispatch('authentication/logout')"><i-->
<!--               class="ab-icon-logout"></i> Logout</a>-->
<!--         </template>-->
<!--         <template v-else>-->
<!--            <a href="#" @click.prevent="$store.commit('globalPopup/setLoginOpen', true)" class="user_profile">-->
<!--               <i class="ab-icon-user-account"></i>-->
<!--               Login-->
<!--            </a>-->
<!--         </template>-->
<!--      </div>-->
      <a href="#" class="close_button"
         @click.prevent="$store.commit('globalPopup/setMenuOpen', false)"
      ></a>
   </div>
</template>

<script>
// import $ from 'jquery';
import {mapState} from 'vuex';
import MenuItems from "./MenuItems";
// import LocaleChanger from "../_common/LocaleChanger";

export default {
   name: "Navigation",
   components: {
      MenuItems,
      // LocaleChanger
   },
   data() {
      return {
         pageContent: {},
         isMobile: false,
         searchInput: ''
      }
   },
   computed: {
      ...mapState([
         'menu',
         'globalPopup',
         'authentication'
      ])
   },
   created() {
      this.$store.dispatch('menu/getMainMenu', 'main-menu');
   },
   mounted() {
      this.resize();
      window.addEventListener('resize', this.resize);
   },
   methods: {
      resize() {
         this.isMobile = window.innerWidth < 768;
      },
      search() {
         this.$router.push({name: 'search', query: {q: this.searchInput}});
      }
   },
   watch: {
      '$route': function () {
         this.$store.commit('globalPopup/setMenuOpen', false);
      },
      'globalPopup.menuOpen': function (val) {
         if (val) {
            document.body.classList.add('oh');
         } else {
            document.body.classList.remove('oh');
         }
      }
   }
}
</script>

<style scoped lang="scss">
.user_profile {
   cursor: pointer;
}
</style>
