export const loading = {
    namespaced: true,
    state: {
        isFullPageLoaderVisible: false
    },
    actions: {},
    mutations: {
        setFullPageLoaderVisible(state, temp) {
            state.isFullPageLoaderVisible = temp;
        }
    }
}
