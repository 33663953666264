import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.apiUrl + '/api/news';

export const news = {
   namespaced: true,
   state: {
      all: [],
      byID: {},
      bySlug: {},
      latest: []
   },
   actions: {
      resetNews({commit}) {
         commit('setNewsToEmpty')
      },
      loadAll({commit}, pageNr) {
         return Http.get(URL + '?page=' + pageNr).then(resp => {
            commit('setAll', resp.data);
            return resp.data;
         })
      },
      getBySlug({commit}, slug) {
         return Http.get(URL + '/slug/' + slug).then(resp => {
            commit('setBySlug', resp.data);
            return resp.data;
         })
      }
   },
   mutations: {
      setNewsToEmpty(state) {
         state.all = []
      },
      setAll(state, data) {
         state.all = state.all.concat(data.news)
      },
      setBySlug(state, data) {
         state.bySlug = data;
      }
   }
}
