import {Http} from "../_helpers/http";
import {config} from "../config";

const URL = config.apiUrl + 'api/menus/';

export const menu = {
   namespaced: true,
   state: {
      all: [],
      byID: {},
      bySlug: {},
      mainMenu: {},
      footerMenu: {},
      bottomFooterMenu: {}
   },
   actions: {
      getAll({commit}) {
         return Http.get(URL).then(resp => {
            commit('setAll', resp.data)
            return resp.data
         })
      },
      getByID({commit}, id) {
         return Http.get(URL + id).then(resp => {
            commit('setByID', resp.data);
            return resp.data
         })
      },
      getBySlug({commit}, slug) {
         return Http.get(URL + 'slug/' + slug).then(resp => {
            commit('setBySlug', resp.data)
            return resp.data
         })
      },
      getMainMenu({commit}) {
         return Http.get(URL + 'main-menu').then(resp => {
            commit('setMainMenu', resp.data)
            return resp.data
         })
      },
      getFooterMenu({commit}) {
         return Http.get(URL + 'footer-menu').then(resp => {
            commit('setFooterMenu', resp.data)
            return resp.data
         })
      },
      getBottomFooterMenu({commit}) {
         return Http.get(URL + 'bottom-footer-menu').then(resp => {
            commit('setBottomFooterMenu', resp.data)
            return resp.data
         })
      }
   },
   mutations: {
      setAll(state, data) {
         state.all = data;
      },
      setByID(state, data) {
         state.byID = data;
      },
      setBySlug(state, data) {
         state.bySlug = data;
      },
      setMainMenu(state, data) {
         state.mainMenu = data;
      },
      setFooterMenu(state, data) {
         state.footerMenu = data;
      },
      setBottomFooterMenu(state, data) {
         state.bottomFooterMenu = data;
      }
   }
}
