import {store} from "./index";
import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.externalServicesApiUrl + '/traffic-news';

export const trafficNews = {
    namespaced: true,
    state: {
        all: [],
        byID: {},
    },
    actions: {
        getAll({commit}) {
            return Http.get(URL, {params: {region: store.state.region.currentRegion.id}}).then(resp => {
                commit('setAll', resp.data.data);
                return resp.data.data;
            })
        },
        getByID({commit}, id) {
            return Http.get(URL + '/' + id, {params: {region: store.state.region.currentRegion.id}}).then(resp => {
                commit('setByID', resp.data.data);
                return resp.data.data;
            })
        }
    },
    mutations: {
        setAll(state, data) {
            state.all = data;
        },
        setByID(state, data) {
            state.byID = data;
        }
    }
}
