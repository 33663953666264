import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.apiUrl + '/api/gallery/';

export const gallery = {
   namespaced: true,
   state: {
      all: {
         images: [],
         categories: []
      },
   },
   actions: {
      loadAll({commit}, data) {
         return Http.get(URL + '?page=' + data.page + '&categories=' + data.categories).then(resp => {
            if (data.clear === true) {
               commit('setImages', resp.data.images);
            } else {
               commit('setAll', resp.data);
            }
            return resp.data;
         })
      },
   },
   mutations: {
      setImages(state, data) {
         state.all.images = data
      },
      setAll(state, data) {
         const sImg = state.all.images.concat(data.images)
         state.all = {
            images: sImg,
            categories: data.categories
         }
      }
   }
}
