<template>
    <div class="custom-select-wrapper" v-if="options && options.length" :class="{is_open: open && !disabled}">
        <div class="select-wrapper">
            <select
                :name="name"
                :id="inputId"
                :value="value"
                @input=" $emit('update', $event.target.value)"
                :required="required"
                :disabled="disabled"
            >
                <option :key="-1+inputId" value="" v-if="!required && placeholder">{{placeholder}}</option>
                <option :key="index+inputId" v-for="(option, index) in options" :value="option.key">
                    {{option.title + ' - ' + option.time + ' - ' + option.delay}}
                </option>
            </select>
        </div>

        <div
            class="custom-select"
            :class="{is_disabled: disabled}"
            :tabindex="tabindex"
            @blur="open = false"
        >
            <div
                class="selected"
                :class="{open: open && !disabled}"
                @click="open = !open"
            >
                {{ selectedValue || placeholder }}
            </div>
            <div
                class="items"
                :class="{selectHide: !open || disabled}"
            >
                <div class="item select_timetable_header">
                    <div class="select_timetable_content">
                        <div class="select_timetable_time">
                            zeit:
                        </div>
                        <div class="select_timetable_title">
                            nach:
                        </div>
                    </div>
                </div>
                <div
                    class="item"
                    v-for="(option, index) of options"
                    :key="index+inputId+'custom'"
                    @click="open=false; $emit('update', option.key)"
                >
                    <div class="select_timetable_content">
                        <div class="select_timetable_time">
                            {{option.time}}<span v-if="option.delay">{{option.delay}}</span>
                        </div>
                        <div class="select_timetable_title">
                            {{option.title}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InputSelectTimetable",
        props: {
            value: [Object, String, Array, Number],
            name: {
                type: String,
                required: true
            },
            inputId: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: false
            },
            tabindex: {
                type: Number,
                required: false,
                default: 0
            },
            options: Array,
            placeholder: String,
            required: Boolean,
            disabled: Boolean,
        },
        model: {
            prop: 'value',
            event: 'update'
        },
        data() {
            return {
                open: false
            }
        },
        computed: {
            selectedValue() {
                let selectedVal = this.options.filter((option) => {
                    return option.key == this.value;
                });

                if (selectedVal.length) {
                    let ret = selectedVal[0].time;
                    if(selectedVal[0].delay) {
                        ret += ' ('+selectedVal[0].delay + ') - ';
                    } else {
                        ret += ' - '
                    }
                    ret += selectedVal[0].title;

                    return ret;
                }
                return null;
            }
        },
        mounted() {
            if (!this.value && this.options && this.options.length && this.required) {
                //this.$emit('update', this.options[0].key);
                this.$emit('update', '');
            } else {
                //this.$emit('update', '');
            }
        }
    }
</script>

<style lang="scss" scoped>
    select {
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
        border: 0 none;
    }
    .select-wrapper{
        width: 0;
        height: 0;
        overflow: hidden;
    }
</style>
