import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.apiUrl + '/api/blog/';

export const blog = {
    namespaced: true,
    state: {
        all: {
            articles: [],
            categories: []
        },
        bySlug: {},
        newPosts: 0
    },
    actions: {
        loadAll({commit}) {
            return Http.get(URL).then(resp => {
                commit('setAll', resp.data);
                return resp.data;
            })
        },
        getBySlug({commit}, slug) {
            return Http.get(URL + 'slug/' + slug).then(resp => {
                commit('setBySlug', resp.data);
                return resp.data;
            })
        },
        checkBlogPosts ({ commit }, data) {
            return Http.get(URL + 'push-news-count?timestamp=' + data).then(resp => {
                commit('setNewPosts', resp.data.count)
                return resp.data
            })
        }
    },
    mutations: {
        setAll(state, data) {
            state.all = data;
        },
        setBySlug(state, data) {
            state.bySlug = data;
        },
        setNewPosts (state, data) {
            state.newPosts = parseInt(data)
        }
    }
}
