<template>
   <a href="#" class="menu_trigger d-flex justify-content-center align-items-center"
      v-if="region.currentRegion"
      :class="{is_active: globalPopup.isMenuOpen}"
      @click.prevent="menuToggle()">
      <i class="ab-icon-menu"></i>
      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16.45" viewBox="0 0 25 16.45">
         <g id="Group_4" data-name="Group 4" transform="translate(0 0)">
            <path id="Fill_1" data-name="Fill 1" d="M0,1.5H25V0H0Z" transform="translate(0 0)" fill="#fff"/>
            <path id="Fill_2" data-name="Fill 2" d="M0,1.5H25V0H0Z" transform="translate(0 7.476)" fill="#fff"/>
            <path id="Fill_3" data-name="Fill 3" d="M0,1.5H25V0H0Z" transform="translate(0 14.95)" fill="#fff"/>
         </g>
      </svg> -->
   </a>
</template>

<script>
   import {mapState} from 'vuex';

   export default {
      name: "MenuTrigger",
      computed: {
         ...mapState([
            'region',
            'globalPopup'
         ])
      },
      methods: {
         menuToggle: function () {
            this.$store.commit('globalPopup/setMenuOpen', !this.globalPopup.isMenuOpen);
         }
      }
   }
</script>
