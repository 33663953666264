<template>
    <fieldset v-if="options && options.length && label" :class="{is_disabled: disabled}">
        <legend><label :for="inputId">{{label}}
            <template v-if="required"> *</template>
        </label></legend>
        <select
            :name="name"
            :id="inputId"
            :value="value"
            @input="$emit('update', $event.target.value)"
            :required="required"
            :disabled="disabled"
        >
            <option :key="-1+inputId" value="" v-if="!required && placeholder">{{placeholder}}</option>
            <option :key="index+inputId" v-for="(option, index) in options" :value="option.key">{{option.value}}
            </option>
        </select>

        <div
            class="custom-select"
            :class="{is_disabled: disabled}"
            :tabindex="tabindex"
            @blur="open = false"
        >
            <div
                class="selected"
                :class="{open: open && !disabled, placeholder: selectedValue != null}"
                @click="open = !open"
            >
                {{ selectedValue || placeholder }}
            </div>
            <div
                class="items"
                :class="{selectHide: !open || disabled}"
            >
                <div
                    class="item"
                    v-if="!required && placeholder"
                    :key="-1+inputId+'custom'"
                    @click="open=false; $emit('update', '')"
                >
                    {{ placeholder }}
                </div>
                <div
                    class="item"
                    v-for="(option, index) of options"
                    :key="index+inputId+'custom'"
                    @click="open=false; $emit('update', option.key)"
                >
                    {{ option.value }}
                </div>
            </div>
        </div>
    </fieldset>
    <div class="custom-select-wrapper" v-else-if="options && options.length && !label">
        <div class="select-wrapper">
            <select
                :name="name"
                :id="inputId"
                :value="value"
                @input="$emit('update', $event.target.value)"
                :required="required"
                :disabled="disabled"
            >
                <option :key="-1+inputId" value="" v-if="!required && placeholder">{{placeholder}}</option>
                <option :key="index+inputId" v-for="(option, index) in options" :value="option.key">{{option.value}}
                </option>
            </select>
        </div>

        <div
            class="custom-select"
            :class="{is_disabled: disabled}"
            :tabindex="tabindex"
            @blur="open = false"
        >
            <div
                class="selected"
                :class="{open: open && !disabled, placeholder: selectedValue != null}"
                @click="open = !open"
            >
                {{ selectedValue || placeholder }}
            </div>
            <div
                class="items"
                :class="{selectHide: !open || disabled}"
            >
                <div
                    class="item"
                    v-if="!required && placeholder"
                    :key="-1+inputId+'custom'"
                    @click="open=false; $emit('update', '')"
                >
                    {{ placeholder }}
                </div>
                <div
                    class="item"
                    v-for="(option, index) of options"
                    :key="index+inputId+'custom'"
                    @click="open=false; $emit('update', option.key)"
                >
                    {{ option.value }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InputSelect",
        props: {
            value: [Object, String, Array, Number],
            name: {
                type: String,
                required: true
            },
            inputId: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: false
            },
            tabindex: {
                type: Number,
                required: false,
                default: 0
            },
            options: Array,
            placeholder: String,
            required: Boolean,
            disabled: Boolean,
        },
        model: {
            prop: 'value',
            event: 'update'
        },
        data() {
            return {
                open: false
            }
        },
        computed: {
            selectedValue() {
                let selectedVal = this.options.filter((option) => {
                    return option.key == this.value;
                });

                if (selectedVal.length && selectedVal[0].value) {
                    return selectedVal[0].value;
                }
                return null;
            }
        },
        mounted() {
            if (!this.value && this.options && this.options.length && this.required) {
                //this.$emit('update', this.options[0].key);
                this.$emit('update', '');
            } else {
                //this.$emit('update', '');
            }
        }
    }
</script>

<style lang="scss" scoped>
    select {
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
        border: 0 none;
    }
    .select-wrapper{
        width: 0;
        height: 0;
        overflow: hidden;
    }
</style>
