<template>
    <div v-if="isVisible">
        <div class="popup" :class="[additionalClass]">
            <div class="inner">
                <div class="header">
                    <slot name="header"></slot>
                    <a href="#" class="close" @click="close"></a>
                </div>
                <div class="content">
                    <slot></slot>
                </div>
                <div class="footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Popup",
        components: {},
        props: {
            visible: {
                type: Boolean,
                default: false
            },
           additionalClass: {
               type: String,
               default: ''
           }
        },
        computed: {
            isVisible(){
                return this.visible;
            }
        },
        methods: {
            close(){
                this.$emit('popupClose', true);
            }
        }
    }
</script>
