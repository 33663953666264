import Vue from "vue";
import Router from "vue-router";
// import {store} from "../_store";

Vue.use(Router);

export const router = new Router({
   mode: "history",
   routes: [
      {
         path: "/:locale(de|en|nl)?",
         component: () => import("../components/BaseComponent"),
         children: [
            {
               path: "404",
               name: "404",
               component: () =>
                  import("../components/staticPages/PageNotFound"),
               meta: {
                  authRequired: false
               }
            },
            // {
            //    path: 'login',
            //    name: 'login',
            //    component: () => import('../components/users/Login'),
            //    meta: {
            //       authRequired: false
            //    }
            // },
            {
               path: "passwort-vergessen",
               name: "forgotPassword",
               component: () => import("../components/users/ForgotPassword"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "passwort-vergessen/:code",
               name: "resetPassword",
               component: () => import("../components/users/ResetPassword"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "register",
               name: "register",
               component: () => import("../components/users/Register"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "trains/:slug",
               name: "train",
               component: () => import("../components/staticPages/Train"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "aktuelle-meldungen",
               name: "traffic-news",
               component: () => import("../components/staticPages/TrafficNews"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "aktuelle-meldungen/traffic/:id",
               name: "traffic-news-message",
               component: () =>
                  import("../components/staticPages/TrafficNewsMessage"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "aktuelle-meldungen/construction/:id",
               name: "construction-work-message",
               component: () =>
                  import("../components/staticPages/ConstructionWorkMessage"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "suche",
               name: "search",
               component: () => import("../components/staticPages/Search"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "tarifrechner",
               name: "rate-calculator",
               component: () =>
                  import("../components/staticPages/RateCalculator"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "bahnhofe/:slug",
               name: "station",
               component: () => import("../components/staticPages/Station"),
               meta: {
                  authRequired: false
               }
            },
            // {
            //    path: 'regions',
            //    name: 'regions',
            //    component: () => import('../components/staticPages/Regions'),
            //    meta: {
            //       authRequired: false
            //    }
            // },
            {
               path: "regions/:slug",
               name: "region",
               component: () => import("../components/staticPages/Region"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "orte/:slug",
               name: "city",
               component: () => import("../components/staticPages/City"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "jobs/:slug",
               name: "job",
               component: () => import("../components/staticPages/Job"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "events/:slug",
               name: "events",
               component: () => import("../components/staticPages/Event"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "news/:slug",
               name: "news",
               component: () => import("../components/staticPages/News"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "linien/:slug",
               name: "line",
               component: () => import("../components/staticPages/Line"),
               meta: {
                  authRequired: false
               }
            },
            // {
            //    path: 'abfahrtsmonitor',
            //    name: 'departureMonitor',
            //    component: () => import('../components/pages/DepartureMonitor'),
            //    meta: {
            //       authRequired: false
            //    }
            // },
            // {
            //    path: 'account',
            //    name: 'account',
            //    component: () => import('../components/users/Account'),
            //    meta: {
            //       authRequired: true
            //    }
            // },
            // {
            //    path: 'account/applications-status',
            //    name: 'applications-status',
            //    component: () => import('../components/users/ApplicationsStatus'),
            //    meta: {
            //       authRequired: true
            //    }
            // },
            // {
            //    path: 'account/job-navigator',
            //    name: 'applications-job-navigator',
            //    component: () => import('../components/users/JobNavigator'),
            //    meta: {
            //       authRequired: true
            //    }
            // },
            // {
            //    path: 'account/applications-wizard',
            //    name: 'applications-wizard',
            //    component: () => import('../components/users/ApplicationsWizard'),
            //    meta: {
            //       authRequired: true
            //    }
            // },
            {
               path: "account/personal-area",
               name: "personal-area",
               component: () => import("../components/users/PersonalArea"),
               meta: {
                  authRequired: true
               }
            },
            {
               path: "/",
               name: "home",
               component: () => import("../components/Home"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "blog/:slug",
               name: "blogArticle",
               component: () => import("../components/pages/BlogArticle"),
               meta: {
                  authRequired: false
               }
            },
            {
               path: "*",
               name: "page",
               component: () => import("../components/pages/Page"),
               meta: {
                  authRequired: false
               }
            }
         ]
      }
   ],
   scrollBehavior(to, from, savedPosition) {
      return new Promise(resolve => {
         if (savedPosition) {
            // History back position, if user click Back button
            resolve(savedPosition);
         } else {
            // Scroll to top of page if the user didn't press the back button
            resolve({ x: 0, y: 0 });
         }
         // resolve({ x: 0, y: 0 })
      });
   }
});
//
// router.beforeEach((to, from, next) => {
//    const loggedIn = store.state.authentication.loggedIn;
//
//    // if (!store.state.region.currentRegion && to.name !== 'regions') {
//    //    if (to.query.region !== undefined) {
//    //       store.getters["region/getAll"].forEach((region) => {
//    //          if (region.slug === to.query.region) {
//    //             store.commit('region/setCurrentRegion', region)
//    //             next()
//    //          }
//    //       })
//    //    } else {
//    //       //Select region if a region is not selected
//    //       return next({
//    //          name: 'regions', params: {
//    //             locale: to.params.locale,
//    //             nextUrl: to.fullPath
//    //          }
//    //       });
//    //    }
//    // } else
//       if (to.meta.authRequired && !loggedIn) {
//       //If logged in required redirect to Login page
//       return next({
//          name: 'login',
//          params: {
//             locale: to.params.locale,
//             nextUrl: to.fullPath
//          }
//       });
//    } else if (loggedIn && to.name === 'login') {
//       return next({
//          name: 'personal-area',
//          params: {
//             locale: to.params.locale
//          }
//       });
//    }
//
//    next();
// });
