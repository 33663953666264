import {store} from "./index";
import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.externalServicesApiUrl + '/timetable-information';
const INTERNAL_API_URL = config.apiUrl + '/api/timetable-information/';

export const timetableInformation = {
    namespaced: true,
    state: {
        timetable: {
            options: [],
            filter: []
        },
        timetableLoaded: false,
        timetableExtraInfo: []
    },
    actions: {
        getTimetable({commit}, parameters) {
            let params = parameters;
            params.region = store.state.region.currentRegion.id;

            return Http.get(URL, {params: params}).then(resp => {
                commit('setTimetable', resp.data.data);
                return resp.data.data;
            })
        },
        getTimetableExtraInfo({commit}) {
            return Http.get(INTERNAL_API_URL).then(resp => {
                commit('setTimetableExtraInfo', resp.data);
                return resp.data;
            })
        }
    },
    mutations: {
        setTimetable(state, data) {
            state.timetable = data;
            state.timetableLoaded = true;
        },
        setTimetableExtraInfo(state, data) {
            state.timetableExtraInfo = data;
        },
        resetTimetable(state) {
            state.timetable = {
                options: [],
                filter: []
            };
            state.timetableLoaded = false;
        }
    }
}
