<template>
   <a href="#" class="search_trigger d-flex justify-content-center align-items-center"
      :class="{ is_active: globalPopup.isSearchOpen }"
      @click.prevent="menuToggle()"
   >
      <i class="ab-icon-search"></i>
     <span> Suche</span>
   </a>
</template>

<script>
   import {mapState} from 'vuex';

   export default {
      name: "SearchTrigger",
      computed: {
         ...mapState([
            'globalPopup'
         ])
      },
      methods: {
         menuToggle: function () {
            this.$store.commit('globalPopup/setSearchOpen', !this.globalPopup.isSearchOpen);
         }
      }
   }
</script>

<style scoped>

</style>
