<template>
   <div
      id="app"
      :class="{
         menu_open: globalPopup.isMenuOpen,
         search_open: globalPopup.isSearchOpen,
         // timetable_open: globalPopup.isTimetableOpen,
      }"
   >
      <vue-progress-bar></vue-progress-bar>
      <vue-confirm-dialog></vue-confirm-dialog>
      <transition name="alert-animation">
         <Alerts
            v-if="alert.message || alert.title"
            :alert-type="alert.type"
            :alert-title="alert.title"
            :alert-message="alert.message"
            :icon="alert.icon"
         />
      </transition>
      <ScrollTopButton />
      <div
         class="page_top_wrapper"
         :class="{ menu_open: globalPopup.isMenuOpen }"
      >
         <div class="row align-items-center">
            <div class="col-4 d-flex justify-content-start align-items-center">
               <MenuTrigger />
            </div>
            <div class="col-4 d-flex justify-content-center">
               <Logo />
            </div>
            <div class="col-4 d-flex justify-content-end align-items-center">
               <router-link class="btn presse-btn" :to="'/karriere'">
                  KARRIERE
               </router-link>
               <router-link class="btn presse-btn" :to="'/presse'">
                  PRESSE
               </router-link>
               <!--               <LocaleChanger/>-->
               <TextSizeToggle />
               <SearchTrigger />
               <!--               <ProfileMenu/>-->
            </div>
         </div>
      </div>
      <LeftButtons />
      <Navigation v-if="region.currentRegion" />
      <Search />
      <TimetablePopup v-if="region.currentRegion" />
      <!-- <ScreenRotator v-if="globalPopup.isTimetableOpen"/> -->
      <DepartureMonitorPopup v-if="region.currentRegion" />
      <Login />

      <div
         class="footer_navigation align-items-center"
         v-if="region.currentRegion"
      >
         <LeftButtons class="align-items-center justify-content-between">
            <MenuTrigger />
         </LeftButtons>
      </div>
      <transition :name="transitionName">
         <router-view :key="$route.fullPath"></router-view>
      </transition>

      <FullPageLoader />
   </div>
</template>

<script>
import { mapState } from "vuex";
import Login from "@/components/users/Login";
import Logo from "./components/_common/Logo";
import Search from "./components/_common/Search";
import Alerts from "./components/_common/Alerts";
import Navigation from "./components/_common/Navigation";
import MenuTrigger from "./components/_common/MenuTrigger";
import LeftButtons from "./components/_common/LeftButtons";
// import ProfileMenu from "./components/_common/ProfileMenu";
// import ScreenRotator from './components/_common/ScreenRotator';
// import LocaleChanger from "./components/_common/LocaleChanger";
import SearchTrigger from "./components/_common/SearchTrigger";
import TextSizeToggle from "./components/_common/TextSizeToggle";
import ScrollTopButton from "./components/_common/ScrollTopButton";
import TimetablePopup from "./components/_common/TimetablePopup";
import FullPageLoader from "./components/_content_elements/FullPageLoader";
import DepartureMonitorPopup from "./components/_common/DepartureMonitorPopup";

export default {
   name: "App",
   components: {
      DepartureMonitorPopup,
      Login,
      Logo,
      Search,
      Alerts,
      Navigation,
      LeftButtons,
      // ProfileMenu,
      MenuTrigger,
      // ScreenRotator,
      SearchTrigger,
      // LocaleChanger,
      TextSizeToggle,
      FullPageLoader,
      TimetablePopup,
      ScrollTopButton,
   },
   metaInfo() {
      return {
         title: this.pageMeta().title,
         content: this.pageMeta().description,
      };
   },
   data() {
      return {
         isDesktop: false,
         refreshTokenTime: 15,
         refreshTokenInterval: null,
         transitionName: "router-anim-left",
      };
   },
   computed: {
      ...mapState([
         "page",
         "alert",
         "region",
         "locales",
         "settings",
         "globalPopup",
         "authentication",
      ]),
      homePageTitle() {
         return this.settings.all.homepage_title;
      },
      siteName() {
         return this.settings.all.site_name;
      },
      pageName() {
         return this.page.bySlug.title;
      },
   },
   methods: {
      refreshToken(val) {
         if (this.refreshTokenInterval) {
            window.clearInterval(this.refreshTokenInterval);
         }
         if (val) {
            this.refreshTokenInterval = window.setInterval(() => {
               this.$store.dispatch("authentication/refresh");
            }, this.refreshTokenTime * 60 * 1000);
         }
      },
      pageMeta() {
         let title = "";
         let description = "";

         if (this.$route.name == "home" && this.homePageTitle) {
            title = this.homePageTitle;
         } else if (
            this.$route.name == "page" &&
            this.pageName &&
            this.siteName
         ) {
            title = this.pageName + " - " + this.siteName;
         } else if (this.siteName) {
            title = this.siteName;
         }

         return {
            title: title,
            description: description,
         };
      },
      resize: function () {
         this.isDesktop = window.innerWidth > 767;
      },
   },
   created() {
      this.$Progress.start();
      //  hook the progress bar to start before we move router-view
      this.$router.beforeEach((to, from, next) => {
         //  does the page we want to go to have a meta.progress object
         if (to.meta.progress !== undefined) {
            let meta = to.meta.progress;
            // parse meta tags
            this.$Progress.parseMeta(meta);
         }
         //  start the progress bar
         this.$Progress.start();
         //  continue to next page
         next();
      });
      //  hook the progress bar to finish after we've finished moving router-view
      this.$router.afterEach(() => {
         //  finish the progress bar
         this.$Progress.finish();
      });

      this.$store.dispatch("settings/getAll");
      this.$store.dispatch("locales/getStringTranslations");
   },
   mounted() {
      this.pageMeta(this.$route);

      this.resize();
      window.addEventListener("resize", this.resize);

      this.$cookiebot.consentBanner();

      this.$moment.locale(this.locales.currentLocale);

      this.$Progress.finish();
   },
   watch: {
      $route: function () {
         this.pageMeta();
      },
      homePageTitle: function () {
         this.pageMeta();
      },
      siteName: function () {
         this.pageMeta();
      },
      pageName: function () {
         this.pageMeta();
      },
   },
};
</script>

<style lang="scss">
@import "./assets/scss/all.scss";

.loading-screen {
   background: rgba(255, 255, 255, 0.5);
   z-index: 10000;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   position: fixed;
   text-align: center;

   svg {
      position: absolute;
      right: 30px;
      bottom: 30px;
      width: 10%;
      stroke: #ce132d;
   }
}
</style>
