import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.apiUrl + '/api/employees/';

export const employee = {
    namespaced: true,
    state: {
        all: [],
        byID: {},
        carousel: []
    },
    actions: {
        getAll({commit}) {
            return Http.get(URL).then(resp => {
                commit('setAll', resp.data);
                return resp.data;
            })
        },
        getByID({commit}, id) {
            return Http.get(URL + id).then(resp => {
                commit('setByID', resp.data);
                return resp.data;
            })
        },
        getCarousel({commit}, {sort_by, order, limit}) {
            return Http.post(URL + 'carousel', {sort_by, order, limit}).then(resp => {
                commit('setCarousel', resp.data);
                return resp.data;
            })
        }
    },
    mutations: {
        setAll(state, data) {
            state.all = data;
        },
        setByID(state, data) {
            state.byID = data;
        },
        setCarousel(state, data) {
            state.carousel = data;
        }
    }
}
