import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.apiUrl + '/api/pages/';

export const page = {
   namespaced: true,
   state: {
      all: [],
      byID: {},
      bySlug: {
         content_elements: []
      },
      homepage: {},
   },
   actions: {
      getAll({commit}) {
         return Http.get(URL).then(resp => {
            commit('setAll', resp.data);
            return resp.data;
         })
      },
      getByID({commit}, id) {
         return Http.get(URL + id).then(resp => {
            commit('setByID', resp.data)
            return resp.data
         })
      },
      getBySlug({commit}, slug) {
         return Http.get(URL + 'slug/' + slug.slug + '?path=' + slug.fullPath).then(resp => {
            commit('setBySlug', resp.data)
            return resp.data
         })
      },
      getHomepage({commit}) {
         return Http.get(URL + 'homepage').then(resp => {
            commit('setHomepage', resp.data)
            return resp.data

         })
      },
      getTranslatedPath({commit}, {oldLocale, slugs}) {
         return Http.post(URL + 'path', {oldLocale, slugs}).then(resp => {
            commit('setTranslatedPath', resp.data)
            return resp
         })
      },
   },
   mutations: {
      setAll(state, data) {
         state.all = data
      },
      setByID(state, data) {
         state.byID = data
      },
      setBySlug(state, data) {
         state.bySlug = data
      },
      setHomepage(state, data) {
         state.homepage = data
      },
      setTranslatedPath(state, data) {
         state.translatedPath = data
      }
   }
}
