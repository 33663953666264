import {config} from "../config";

export const alert = {
   namespaced: true,
   state: {
      type: null,
      title: null,
      message: null,
      icon: false
   },
   actions: {
      success({commit}, {message, title, icon = false}) {
         commit('success', {message, title, icon});
         let succesTimeout = window.setTimeout(() => {
            commit('clear');
            window.clearTimeout(succesTimeout);
         }, config.alertTimeout);
      },
      error({commit}, {message, title, icon = false}) {
         commit('error', {message, title, icon});
         let errorTimeout = window.setTimeout(() => {
            commit('clear');
            window.clearTimeout(errorTimeout);
         }, config.alertTimeout);
      },
      clear({commit}) {
         commit('clear');
      }
   },
   mutations: {
      success(state, {message, title, icon = false}) {
         state.type = 'alert-success';
         state.title = title ? title : null;
         state.message = message;
         state.icon = icon;
      },
      error(state, {message, title, icon = false}) {
         state.type = 'alert-danger';
         state.title = title ? title : null;
         state.message = message;
         state.icon = icon;
      },
      clear(state) {
         state.type = null;
         state.title = null;
         state.message = null;
         state.icon = false;
      }
   }
}
