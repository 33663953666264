<template>
    <a href="#" class="text_size_toggle d-flex justify-content-center align-items-center"
        @click.prevent="textToggle()"
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="29" viewBox="0 0 53 29">
            <text id="A" transform="translate(22 23)" fill="#fff" font-size="24" font-family="Frutiger-Light, Frutiger" font-weight="300"><tspan x="0" y="0">A</tspan></text>
            <text id="A_Copy" data-name="A Copy" transform="translate(8 21)" fill="#fff" font-size="18" font-family="Frutiger-Light, Frutiger" font-weight="300"><tspan x="0" y="0">A</tspan></text>
            <text id="_" data-name="+" transform="translate(40 21)" fill="#fff" font-size="20" font-family="Frutiger-Light, Frutiger" font-weight="300"><tspan x="0" y="0">+</tspan></text>
            <text id="_-" data-name="-" transform="translate(0 23)" fill="#fff" font-size="20" font-family="Frutiger-Light, Frutiger" font-weight="300"><tspan x="0" y="0">-</tspan></text>
        </svg>
    </a>
</template>

<script>
    export default {
        name: "TextSizeToggle",
        data() {
            return {
                normal: true
            }
        },
        methods: {
            textToggle: function () {
                if(this.normal) {
                    document.documentElement.className = 'text_size_large';
                    this.normal = false;
                } else {
                    document.documentElement.className = '';
                    this.normal = true;
                }
            }
        }
    }
</script>

<style scoped>

</style>
