import axios from 'axios'
import {store} from "../_store";
import {config} from "../config";
import {router} from "./router";

export const Http = axios.create()
Http.interceptors.request.use(function (request) {
    request.headers.Locale = store.state.locales.currentLocale
    request.headers.Region = store.state.region.currentRegion !== null ? store.state.region.currentRegion.id : ''

    return request
})

let user = JSON.parse(localStorage.getItem('user'));

export const AuthHttp = axios.create()
AuthHttp.interceptors.request.use(function (request) {
    request.headers.Authorization = 'Bearer ' + user.token
    request.headers.Locale = store.state.locales.currentLocale
    request.headers.Region = store.state.region.currentRegion !== null ? store.state.region.currentRegion.id : ''

    return request
})

AuthHttp.interceptors.response.use(response => {
    return response
}, error => {
    if (error.response.status === 401) {
        // need login
        Http.post(config.apiUrl + '/api/refresh', {token: user.token}).then(data => {
            data = data.data;
            if (data.token) {
                localStorage.setItem('user', JSON.stringify(data));
            }
        }).catch(function () {
            store.dispatch('authentication/logout')
            router.push({name: 'login'});
        });
    } else if (error.response.status === 403) { // no access
        router.push({name: 'login'});
    }

    return Promise.reject(error)
})
