<template>
   <div class="search_wrapper d-flex justify-content-center text-center"
        :class="{ is_open: globalPopup.isSearchOpen }"
   >
      <div class="navigation_overlay" @click="$store.commit('globalPopup/setSearchOpen', false)"></div>
      <div class="navigation_inner search-inner">
         <h6 class="font-weight-light text-muted w-100 info-text">
            Suchen Sie nach Verbindungen, Angeboten, Neuigkeiten u.v.m
         </h6>
         <div class="d-flex w-100 input-wrapper position-relative">
            <i class="ab-icon-search search-icon"></i>
            <input ref="searchInput"
                   v-model="queryString"
                   class="search-input"
                   type="text"
                   placeholder="Suche"
                   autocomplete="off"
                   @focus="autoCompleteVisibility = true"
                   @blur="blurSearchInput"
                   @keyup.enter="openSearchResultPage"
            >
            <div class="m-autocomplete custom-scrollbar" v-if="search.autoSuggest"
                 :class="{ 'is-active': autoCompleteVisibility }">
               <div class="m-autocomplete__item" v-for="(item, index) in search.autoSuggest" :key="'m-search' + index"
                    @click="searchResultClicked(item)">
                  {{ item.title }}

                  <span class="info">{{ getType(item) }}</span>
               </div>

               <div v-if="queryString" class="m-autocomplete__item" @click="openSearchResultPage">mehr...</div>
            </div>
            <button @click="queryString = ''" v-if="queryString !== ''" class="clear-input"><i
               class="clear-icon ab-icon-x"></i></button>
         </div>
      </div>
      <a href="#" class="close_button"
         @click.prevent="$store.commit('globalPopup/setSearchOpen', false)"
      ></a>
   </div>
</template>

<script>
   import {mapState} from 'vuex';

   export default {
      name: "Search",
      components: {},
      data() {
         return {
            pageContent: {},
            isMobile: false,
            queryString: '',
            autoCompleteVisibility: false,
         }
      },
      computed: {
         ...mapState([
            'locales',
            'search',
            'globalPopup',
            'authentication'
         ])
      },
      mounted() {
         this.resize();
         window.addEventListener('resize', this.resize);
      },
      methods: {
         getType(item) {
            switch (item.type) {
               case 2:
                  return 'Linie'
               case 3:
                  return 'Bahnhof'
               default:
                  return 'Seite'
            }
         },
         blurSearchInput: function () {
            setTimeout(() => {
               this.autoCompleteVisibility = false
            }, 200);
         },
         resize: function () {
            this.isMobile = window.innerWidth < 768;
         },
         openSearchResultPage: function () {
            this.$store.commit('globalPopup/setSearchOpen', false)
            this.$store.commit('search/setAutoSuggest', {result: []})
            this.$router.push({name: 'search', query: {q: this.queryString}});
         },
         searchResultClicked: function (item) {
            this.$store.commit('globalPopup/setSearchOpen', false)
            this.$store.commit('search/setAutoSuggest', {result: []})

            if (item.slugs[this.locales.currentLocale] !== null) {
               this.$router.push({path: item.slugs[this.locales.currentLocale]});
            } else {
               this.$router.push({path: item.slugs[this.locales.defaultLocale]});
            }
         }
      },
      watch: {
         'queryString': function (val) {
            this.$store.dispatch('search/autoSuggest', val)
         },
         'globalPopup.isSearchOpen': function (val) {
            if (val) {
               document.body.classList.add('oh');
               this.$nextTick(this.$refs.searchInput.focus())
            } else {
               document.body.classList.remove('oh');
            }
         }
      }
   }
</script>

<style lang="scss" scoped>
   span.info {
      font-size: 12px;
   }
</style>
