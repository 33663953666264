export const globalPopup = {
   namespaced: true,
   state: {
      isLoginOpen: false,
      isMenuOpen: false,
      isTimetableOpen: false,
      isSearchOpen: false,
      isDepartureMonitorOpen: false,
   },
   mutations: {
      setLoginOpen(state, status) {
         this.commit("globalPopup/setAllClosed")
         state.isLoginOpen = status;
      },
      setMenuOpen(state, status) {
         this.commit("globalPopup/setAllClosed")
         state.isMenuOpen = status;
      },
      setTimetableOpen(state, status) {
         this.commit("globalPopup/setAllClosed")
         state.isTimetableOpen = status;
      },
      setDepartureMonitorOpen(state, status) {
         this.commit("globalPopup/setAllClosed")
         state.isDepartureMonitorOpen = status;
      },
      setSearchOpen(state, status) {
         this.commit("globalPopup/setAllClosed")
         state.isSearchOpen = status;
      },
      setAllClosed(state) {
         state.isLoginOpen = false;
         state.isMenuOpen = false;
         state.isTimetableOpen = false;
         state.isSearchOpen = false;
         state.isDepartureMonitorOpen = false;
      }
   }
}
