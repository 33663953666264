import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.apiUrl + '/api/stations/';

export const station = {
   namespaced: true,
   state: {
      all: [],
      byID: {},
      bySlug: {},
      byCitySlug: [],
      list: {}
   },
   actions: {
      getAll({commit}) {
         return Http.get(URL).then(resp => {
            commit('setAll', resp.data);
            return resp.data;
         })
      },
      getByID({commit}, id) {
         return Http.get(URL + id).then(resp => {
            commit('setByID', resp.data);
            return resp.data;
         })
      },
      getBySlug({commit}, slug) {
         return Http.get(URL + 'slug/' + slug).then(resp => {
            commit('setBySlug', resp.data);
            return resp.data;
         })
      },
      getByCitySlug({commit}, slug) {
         return Http.get(URL + 'city/slug/' + slug).then(resp => {
            commit('setByCitySlug', resp.data);
            return resp.data;
         })
      },
      getList({commit}, {sort_by, order, limit, page}) {
         return Http.get(URL + 'list?page=' + page + '&sort_by=' + sort_by + '&order=' + order + '&limit=' + limit).then(resp => {
            commit('setList', resp.data);
            return resp.data;
         })
      }
   },
   mutations: {
      setAll(state, data) {
         state.all = data;
      },
      setByID(state, data) {
         state.byID = data;
      },
      setBySlug(state, data) {
         state.bySlug = data;
      },
      setByCitySlug(state, data) {
         state.byCitySlug = data;
      },
      setList(state, data) {
         state.list = data;
      }
   }
}
