require("es6-shim");

import "core-js/es/object";
import Vue from "vue";
import VueProgressBar from "vue-progressbar";
import App from "./App.vue";
import { store } from "./_store";
import { router } from "./_helpers";
import { config } from "./config";
import Meta from "vue-meta";
import Nl2br from "./components/_common/Nl2br";
import * as VueGoogleMaps from "vue2-google-maps";
import VueForm from "vue-form";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import VueConfirmDialog from "vue-confirm-dialog";
import VueCookieBot from "@ambitiondev/vue-cookiebot-plugin";
import Clipboard from "v-clipboard";
import AOS from "aos";
import VueMatomo from "vue-matomo";
import "aos/dist/aos.css";
import "swiper/css/swiper.css";
import GmapCluster from "vue2-google-maps/dist/components/cluster";

require("moment/locale/de");
require("moment/locale/nl");
import moment from "moment/src/moment";
/* eslint-disable-next-line */
// import { $ } from "jquery";

Vue.config.productionTip = false;
Vue.use(Meta);
Vue.use(Clipboard);
Vue.use(AOS);
Vue.use(VueCookieBot, {
   cookieBotID: config.cookieBotID,
   defaultLocale: "de",
});
Vue.use(VueConfirmDialog);
Vue.use(require("vue-moment"), {
   moment,
});
Vue.use(VueGoogleMaps, {
   load: {
      key: config.googleMapApiKey,
   },
});
Vue.use(VueForm);
Vue.use(VueProgressBar, {
   color: "#CE132D",
   failedColor: "#6E6E6E",
   thickness: "4px",
   transition: {
      speed: "0.6s",
      opacity: "0.4s",
      termination: 700,
   },
   autoRevert: true,
   location: "top",
   inverse: false,
});

Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
Vue.component("nl2br", Nl2br);
Vue.component("GmapCluster", GmapCluster);

Vue.mixin({
   methods: {
      cmsAsset: function (path) {
         return config.mediaPath + path;
      },

      cmsAssetOrPlaceholder: function (path) {
         if (path) {
            return config.mediaPath + path;
         }
         return config.mediaPath + "/placeholder.jpg";
      },
      isElementInViewport(el) {
         var rect = el.getBoundingClientRect();

         return (
            rect.top >
            (window.innerHeight || document.documentElement.clientHeight)
         );
      },
      scrollToElement(el, mobileOnly) {
         if (mobileOnly && window.innerWidth > 768) return;

         const y = el.getBoundingClientRect().top + window.scrollY;
         window.scroll({
            top: y - 30,
            behavior: "smooth",
         });
      },
   },
});

Vue.prototype.__t = function (string) {
   if (
      this.$store.state.locales.stringTranslations &&
      this.$store.state.locales.stringTranslations[string]
   ) {
      return this.$store.state.locales.stringTranslations[string][
         this.$store.state.locales.currentLocale
      ];
   }
   return string;
};

Vue.use(VueMatomo, {
   // Configure your matomo server and site by providing
   host: "https://analytics.abellio.de/",
   siteId: 1,

   // Changes the default .js and .php endpoint's filename
   // Default: 'matomo'
   trackerFileName: "matomo",

   // Overrides the autogenerated tracker endpoint entirely
   // Default: undefined
   // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

   // Overrides the autogenerated tracker script path entirely
   // Default: undefined
   // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

   // Enables automatically registering pageviews on the router
   router: router,

   // Enables link tracking on regular links. Note that this won't
   // work for routing links (ie. internal Vue router links)
   // Default: true
   enableLinkTracking: true,

   // Require consent before sending tracking information to matomo
   // Default: false
   requireConsent: false,

   // Whether to track the initial page view
   // Default: true
   trackInitialView: true,

   // Run Matomo without cookies
   // Default: false
   disableCookies: false,

   // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
   // Default: false
   enableHeartBeatTimer: false,

   // Set the heartbeat timer interval
   // Default: 15
   heartBeatTimerInterval: 15,

   // Whether or not to log debug information
   // Default: false
   debug: true,

   // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
   // Default: undefined
   userId: undefined,

   // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
   // Default: undefined, example '*.example.com'
   cookieDomain: undefined,

   // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
   // Default: undefined, example: '*.example.com'
   domains: undefined,

   // A list of pre-initialization actions that run before matomo is loaded
   // Default: []
   // Example: [
   //   ['API_method_name', parameter_list],
   //   ['setCustomVariable','1','VisitorType','Member'],
   //   ['appendToTrackingUrl', 'new_visit=1'],
   //   etc.
   // ]
   preInitActions: [],
});

new Vue({
   created() {
      AOS.init();
   },
   store: store,
   router: router,
   render: (h) => h(App),
}).$mount("#app");
