<template>
    <fieldset :class="{is_disabled: disabled}" v-if="label">
        <legend><label :for="inputId">{{label}}<template v-if="required"> *</template></label></legend>
        <input :type="type || 'text'"
               :name="name"
               :id="inputId"
               :placeholder="placeholder"
               :value="value"
               @input="$emit('update', $event.target.value.replace(/[^\d]/g,'')); $event.target.value = $event.target.value.replace(/[^\d]/g,'');"
               @blur="$emit('blur', $event.target.value)"
               @focus="$emit('focus', $event.target.value)"
               :required="required"
               :disabled="disabled"
               @keypress="isNumber($event)"
               @keyup.enter="$emit('keyupEnter')"
        >
    </fieldset>
    <input :type="type || 'text'" v-else
           :name="name"
           :id="inputId"
           :placeholder="placeholder"
           :value="value"
           @input="$emit('update', $event.target.value.replace(/[^\d]/g,'')); $event.target.value = $event.target.value.replace(/[^\d]/g,'');"
           @blur="$emit('blur', $event.target.value)"
           @focus="$emit('focus', $event.target.value)"
           :required="required"
           :disabled="disabled"
           @keypress="isNumber($event)"
           @keyup.enter="$emit('keyupEnter')"
    >
</template>

<script>
    export default {
        name: "InputNumber",
        props: {
            value: String,
            type: {
                type: String,
            },
            name: {
                type: String,
                required: true
            },
            inputId: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: false
            },
            placeholder: String,
            required: Boolean,
            disabled: Boolean,
        },
        model: {
            prop: 'value',
            event: 'update'
        },
        methods: {
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                const charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            }
        }
    }
</script>

<style scoped>

</style>
