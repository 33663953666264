<template>
 <div
  class="login_page p-0 slide-top-popup timetable_element"
  :class="{ is_open: globalPopup.isLoginOpen }"
 >
  <vue-form :state="formstate" @submit.prevent="loginForm" id="registerForm">
   <div class="timetable_wrapper">
    <div class="timetable_inner_wrapper login_section_wrapper">
     <div class="navigation_overlay"></div>
     <h5 class="timetable_title">{{ __t('login_popup.title') }}</h5>
       <div class="desc"><p>{{ __t('login_popup.description') }}</p></div>
     <div class="login_wrapper pt-2 pb-3">
      <validate tabindex="1">
       <div class="login_email_wrapper">
        <span class="icon">
         <i class="ab-icon-user-account"></i>
        </span>
        <InputText
         name="email"
         placeholder="E-Mail-Adresse"
         inputId="registerEmail"
         v-model="model.email"
         type="email"
         :required="true"
         :disabled="false"
        />
       </div>
       <field-messages name="email" class="vf-error_message">
        <template slot="required" slot-scope="state">
         <span v-if="state.$dirty || state.$submitted"
          >Bitte füllen Sie dieses Pflichtfeld aus.</span
         >
        </template>
        <template slot="email" slot-scope="state">
         <span v-if="!state.$valid && state.$dirty"
          >Bitte geben Sie eine gültige Email-Adresse.</span
         >
        </template>
       </field-messages>
      </validate>

      <validate tabindex="2" :custom="{ passwordStrength: passwordStrength }">
       <div class="login_password_wrapper">
        <span class="icon">
         <i class="ab-icon-lock"></i>
        </span>
        <InputText
         name="password"
         placeholder="Passwort"
         inputId="registerPassword"
         v-model="model.password"
         type="password"
         :required="true"
         :disabled="false"
        />
       </div>
       <field-messages name="password" class="vf-error_message">
        <template slot="required" slot-scope="state">
         <span v-if="state.$dirty || state.$submitted"
          >Bitte füllen Sie dieses Pflichtfeld aus.</span
         >
        </template>
        <template slot="passwordStrength" slot-scope="state">
         <span v-if="state.$dirty || state.$submitted"
          >{{ "Password" }} requires UpperCase, LowerCase, Number/SpecialChar
          and min 8 Chars</span
         >
        </template>
       </field-messages>
      </validate>
      <div class="register_links">
       <router-link :to="{ name: 'register' }" class=""
        >Registrieren</router-link
       >
       <span class="seperator"> | </span>
       <router-link :to="{ name: 'forgotPassword' }" class=""
        >Passwort vergessen?</router-link
       >
      </div>

      <div
       class="d-flex flex-column align-items-center login-button"
       style="margin-top: 30px"
      >
       <button class="btn btn-grey" :disabled="formSending">
        <template v-if="formSending">Anmelden ...</template>
        <template v-else>Anmelden</template>
       </button>
      </div>
     </div>
    </div>
   </div>
  </vue-form>
  <a
   href="#"
   class="close_button"
   @click.prevent="$store.commit('globalPopup/setLoginOpen', false)"
  ></a>
 </div>
</template>

<script>
import { mapState } from "vuex";
import { config } from "../../config";

import { InputText } from "../../components/_form";

export default {
 name: "Login",
 components: {
  InputText,
 },
 data() {
  return {
   formstate: {},
   model: [],
   formSending: false,
  };
 },
 computed: {
  ...mapState(["alert", "globalPopup"]),
  assetsPath() {
   return config.apiUrl + "/storage/app/media";
  },
 },
 methods: {
  /*
            validEmail(email) {
                let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },*/
  loginForm() {
   if (this.formstate.$invalid) {
    document.getElementsByClassName("vf-field-invalid")[0].focus();
    return;
   }
   this.formSending = true;
   this.$store.dispatch("authentication/login", {
    email: this.model.email,
    password: this.model.password,
   });
  },
  passwordStrength(value) {
   return /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
    value
   );
  },
 },
 watch: {
  "alert.type": function (val) {
   this.formSending = false;
   if (val === "alert-success") {
    this.model = [];
    this.formstate._reset();
   }
  },
 },
};
</script>

<style scoped lang="scss">
.login-button {
 bottom: initial;
}
</style>
