<template>
    <fieldset v-if="options && options.length && label" :class="{is_disabled: disabled}">
        <legend><label :for="inputId">{{label}}<template v-if="required"> *</template></label></legend>
        <div class="custom-checkboxes-wrapper">
            <label class="checkbox_option" v-for="(option, index) in options" :key="index+inputId">
                <input type="radio"
                       :name="name"
                       :value="option.key"
                       :required="required"
                       :disabled="disabled"
                       v-model='model'
                > <span v-html="option.value"></span>
            </label>
        </div>
    </fieldset>
    <div class="custom-checkboxes-wrapper" v-else-if="options && options.length && !label">
        <label class="checkbox_option" v-for="(option, index) in options" :key="index+inputId">
            <input type="radio"
                   :name="name"
                   :value="option.key"
                   :required="required"
                   :disabled="disabled"
                   v-model='model'
            > <span v-html="option.value"></span>
        </label>
    </div>
</template>

<script>
    export default {
        name: "InputRadioButtons",
        props: {
            value: [Object, String, Array],
            name: {
                type: String,
                required: true
            },
            inputId: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: false
            },
            tabindex: {
                type: Number,
                required: false,
                default: 0
            },
            options: Array,
            placeholder: String,
            required: Boolean,
            disabled: Boolean,
        },
        model: {
            prop: 'value',
            event: 'update'
        },
        data() {
            return {
                model: ''
            }
        },
        mounted() {

        },
        watch: {
            model: function(val) {
                this.$emit('update', val)
            },
            value: function (val) {
                if(val) {
                    this.model = val;
                } else {
                    this.model = '';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
