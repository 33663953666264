<template>
  <fieldset v-if="options && options.length && label" :class="{ is_disabled: disabled }">
    <legend><label :for="inputId">{{ label }}<template v-if="required"> *</template></label></legend>
    <div class="custom-draggable-wrapper">
      <draggable
        class="list-group pb-5"
        :list="filOptions"
        @end="onDragEnd"
        :group="{
          name: 'people_' + inputId,
          pull: false,
          put: false
        }"
      >
        <label class="checkbox_option" v-for="(option) in filOptions" :key="option.key">
          <input
            type="checkbox"
            :name="name + '[]'"
            :value="option.key"
            :required="required"
            :readonly="true"
            v-model="model"
          >
          <span class="custom-draggable-wrapper_icon">
            <img :src="require('@/assets/images/icons/drag-handle.svg')" alt="">
          </span>
          <span style="margin-right: 5px;">{{ option.number }}.</span>
          <span v-html="option.value"></span>
        </label>
      </draggable>
    </div>
  </fieldset>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: "InputDraggable",
  components: {
    draggable
  },
  props: {
    value: [Object, String, Array, Boolean],
    name: {
      type: String,
      required: true
    },
    inputId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    options: Array,
    placeholder: String,
    required: Boolean,
    disabled: {
      type: Boolean,
      default: true
    },
  },
  model: {
    prop: 'value',
    event: 'update'
  },
  data() {
    return {
      model: [],
      filOptions: []
    };
  },
  mounted() {
    if (this.value) this.model = this.value;
    this.filOptions = this.options.map((option, index) => ({
      ...option,
      number: index + 1
    }));
    if (this.required) this.model = this.filOptions.map(item => item.value);
  },
  watch: {
    model: function (val) {
      this.$emit('update', val);
    },
    value: function (val) {
      this.model = val ? val : [];
    }
  },
  methods: {
    onDragEnd() {
      this.filOptions.forEach((option, index) => {
        option.number = index + 1;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
