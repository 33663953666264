import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.externalServicesApiUrl + 'departure-monitor';

export const departureMonitor = {
   namespaced: true,
   state: {
      allStations: [],
      stationInfo: {
         trackInfo: [],
         name: ''
      },
      trainInfo: {
         stops: []
      }
   },
   actions: {
      getAllStations({commit}) {
         return Http.get(URL).then(resp => {
            commit('setAllStations', resp.data.data);
            return resp.data.data;
         })
      },
      getStationInfo({commit}, stationId) {
         return Http.get(URL + '/station?station=' + stationId).then(resp => {
            commit('setStationInfo', resp.data.data);
            return resp.data.data;
         })
      },
      getTrainInfo({commit}, trainId) {
         return Http.get(URL + '/train?train=' + trainId).then(resp => {
            commit('setTrainInfo', resp.data.data);
            return resp.data.data;
         })
      }
   },
   mutations: {
      setAllStations(state, data) {
         state.allStations = data;
      },
      setStationInfo(state, data) {
         state.stationInfo = data;
      },
      setTrainInfo(state, data) {
         state.trainInfo = data;
      }
   }
}
