export const savedRoutes = {
   namespaced: true,
   state: {
      saved: localStorage.getItem('saved_routes') && JSON.parse(localStorage.getItem('saved_routes')) || [],
      localStorageKey: 'saved_routes'
   },
   mutations: {
      saveToState(state, data) {
         state.saved.push(data)
         localStorage.setItem(state.localStorageKey, JSON.stringify(state.saved))
      },
      updateRoutesState(state, data) {
         state.saved = data;
         localStorage.setItem(state.localStorageKey, JSON.stringify(state.saved))
      }
   },
   actions: {
      saveRoutes({commit}, data) {
         commit('saveToState', data);
      },
      updateRoutes({commit}, data) {
         commit('updateRoutesState', data);
      }
   }
}
