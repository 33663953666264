<template>
   <fieldset :class="{is_disabled: disabled}" v-if="label">
      <legend><label :for="inputId">{{label}}
         <template v-if="required"> *</template>
      </label></legend>
      <input type="file"
             :name="name"
             :id="inputId"
             ref="file"
             :placeholder="placeholder"
             :value="value"
             @input="$emit('update', $event.target.value)"
             @blur="$emit('blur', $event.target.value)"
             @focus="$emit('focus', $event.target.value)"
             :required="required"
             :disabled="disabled"
             @change="$emit('fileUploaded', $refs.file.files)"
             @keyup.enter="$emit('keyupEnter')"
             :multiple="multiple"
      >
   </fieldset>
   <input type="file" v-else
          :name="name"
          :id="inputId"
          ref="file"
          :placeholder="placeholder"
          :value="value"
          @input="$emit('update', $event.target.value)"
          @blur="$emit('blur', $event.target.value)"
          @focus="$emit('focus', $event.target.value)"
          :required="required"
          :disabled="disabled"
          @change="$emit('fileUploaded', $refs.file.files)"
          @keyup.enter="$emit('keyupEnter')"
          :multiple="multiple"
   >
</template>

<script>
   export default {
      name: "InputFile",
      props: {
         value: String,
         type: {
            type: String,
         },
         name: {
            type: String,
            required: true
         },
         inputId: {
            type: String,
            required: true
         },
         label: {
            type: String,
            required: false
         },
         multiple: {
            type: Boolean,
            default: false
         },
         placeholder: String,
         required: Boolean,
         disabled: Boolean,
      },
      model: {
         prop: 'value',
         event: 'update',
         file: ''
      }
   }
</script>
