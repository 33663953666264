import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.apiUrl + '/api/dramatic-events/';

export const dramaticEvent = {
   namespaced: true,
   state: {
      event: null
   },
   actions: {
      loadAll({commit}) {
         return Http.get(URL).then(resp => {
            commit('setAll', resp.data);
            return resp.data;
         })
      }
   },
   mutations: {
      setAll(state, data) {
         state.event = data;
      }
   }
}
