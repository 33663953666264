<template>
    <fieldset :class="{is_disabled: disabled}" v-if="label">
        <legend><label :for="inputId">{{ label }}
            <template v-if="required"> *</template>
        </label></legend>
        <VueCtkDateTimePicker
                :format="'DD.MM.YYYY'"
                :formatted="'DD.MM.YYYY'"
                :noHeader="true"
                :only-date="true"
                :noClearButton="true"
                :color="'#000000'"
                :buttonColor="'#CE132D'"
                :name="name"
                :id="inputId"
                :no-label="true"
                :placeholder="placeholder"
                :label="label"
                :value="value"
                v-model="model"
                :required="required"
                :disabled="disabled"
        ></VueCtkDateTimePicker>
    </fieldset>
</template>

<script>
    export default {
        name: "InputDatePicker",
        props: {
            value: String,
            name: {
                type: String,
                required: true
            },
            inputId: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: false
            },
            tabindex: {
                type: Number,
                required: false,
                default: 0
            },
            placeholder: String,
            required: Boolean,
            disabled: Boolean,
        },
        model: {
            prop: 'value',
            event: 'update'
        },
        data() {
            return {
                model: ''
            }
        },
        mounted() {

        },
        watch: {
            model: function (val) {
                this.$emit('update', val)
            },
            value: function (val) {
                if (val) {
                    this.model = val;
                } else {
                    this.model = '';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
