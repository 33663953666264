import { store } from "./index";
import { config } from "../config";
import { Http } from "../_helpers/http";

const URL = config.externalServicesApiUrl + "/auto-suggest-stations";

export const autoSuggestPlaces = {
   namespaced: true,
   state: {
      places: [],
   },
   actions: {
      getAutoSuggestion({ commit }, query) {
         return Http.get(URL, {
            params: {
               search: query,
               region: store.state.region.currentRegion.id,
            },
         }).then((resp) => {
            commit("setPlaces", resp.data);
            return resp.data;
         });
      },
   },
   mutations: {
      setPlaces(state, data) {
         state.places = data;
      },
   },
};
