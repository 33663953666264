import {config} from "../config";
import {Http} from "../_helpers/http";

const URL = config.apiUrl + '/api/regions/';

let currentRegion = {
   id: 1,
   title: "Mitteldeutschland",
   slug: "mitteldeutschland",
   description: "",
   color: "#a83535",
   longitude: "12.45944009999997",
   latitude: "51.629271457059836",
   map_zoom: 7,
}

localStorage.setItem('currentRegion', JSON.stringify(currentRegion));

export const region = {
   namespaced: true,
   state: {
      all: [
         {
            id: 1,
            title: "Mitteldeutschland",
            slug: "mitteldeutschland",
            description: "",
            color: "#a83535",
            longitude: "12.45944009999997",
            latitude: "51.629271457059836",
            map_zoom: 7,
         }
      ],
      byID: {},
      bySlug: {},
      currentRegion: currentRegion
   },
   actions: {
      getByID({commit}, id) {
         return Http.get(URL + id).then(resp => {
            commit('setByID', resp.data)
            return resp.data
         })
      },
      getBySlug({commit}, slug) {
         return Http.get(URL + 'slug/' + slug).then(resp => {
            commit('setBySlug', resp.data)
            return resp.data
         })
      }
   },
   getters: {
      getAll: state => state.all
   },
   mutations: {
      setAll(state, data) {
         state.all = data;
      },
      setByID(state, data) {
         state.byID = data;
      },
      setBySlug(state, data) {
         state.bySlug = data;
      },
      setCurrentRegion(state, data) {
         localStorage.setItem('currentRegion', JSON.stringify(data));
         state.currentRegion = data;
      },
      removeCurrentRegion(state) {
         localStorage.setItem('currentRegion', null);
         state.currentRegion = null;
      }
   }
}
